import { find } from "lodash"
import React, { useState, useEffect } from "react"
import { putRequest, postRequest } from "../../helpers/api"
import AddressFinder, { buildAddress, AddressInfo } from "../AddressFinder"

const initialValues = {
  company_name: "",
  first_name: "",
  last_name: "",
  email: "",
  telephone: "",
  address_line_1: "",
  address_line_2: "",
  address_town: "",
  address_postcode: "",
  address_country: "",
  invite_message: "",
  relationship_to_will_maker: "unspecified",
}

const ContactForm = ({ contact, nameChangable, showInviteText, onSave, onClose }) => {
  const [isSaving, setIsSaving] = useState(false)
  const [errors, setErrors] = useState({})
  const [form, setValues] = useState({ ...initialValues })

  useEffect(() => {
    if (contact) setValues({ ...contact })
  }, [contact])

  return (
    <>
      {nameChangable &&
        <>
          <div className="form-field">
            <label htmlFor="contact_first_name">First name</label>
            <input type="text" id="contact_first_name" value={form.first_name} onChange={e =>  setValues({ ...form, first_name: e.target.value })} />
            {find(errors, row => row.field === "first_name") && <span className="hint -error">{find(errors, row => row.field === "first_name").errors.join(", ")}</span>}
          </div>
          <div className="form-field">
            <label htmlFor="contact_last_name">Last name</label>
            <input type="text" id="contact_last_name" value={form.last_name} onChange={e =>  setValues({ ...form, last_name: e.target.value })} />
            {find(errors, row => row.field === "last_name") && <span className="hint -error">{find(errors, row => row.field === "last_name").errors.join(", ")}</span>}
          </div>
        </>
      }
      <div className="form-field">
        <label htmlFor="contact_email">Email</label>
        <input type="email" id="contact_email" value={form.email} onChange={e =>  setValues({ ...form, email: e.target.value })} />
        {find(errors, row => row.field === "email") && <span className="hint -error">{find(errors, row => row.field === "email").errors.join(", ")}</span>}
      </div>
      <div className="form-field">
        <label htmlFor="contact_telephone">Primary contact number</label>
        <input type="tel" id="contact_telephone" value={form.telephone} onChange={e =>  setValues({ ...form, telephone: e.target.value })} />
        {find(errors, row => row.field === "telephone") && <span className="hint -error">{find(errors, row => row.field === "telephone").errors.join(", ")}</span>}
      </div>
      <AddressFinder
        errors={errors}
        value={buildAddress({ ...form })}
        onChange={address => {
          setValues({ ...form, ...address })
        }}
      />
      {showInviteText &&
        <div className="form-field">
          <label htmlFor="contact_invite_message">Your message to {form.first_name}</label>
          <textarea id="contact_invite_message" value={form.invite_message} onChange={e =>  setValues({ ...form, invite_message: e.target.value })} />
          <span className="hint">Leaving a personalised message will increase the chances of your trusted friend signing up.</span>
          {find(errors, row => row.field === "invite_message") && <span className="hint -error">{find(errors, row => row.field === "invite_message").errors.join(", ")}</span>}
        </div>
      }

      <div className="flex">
        <button
          className="button -primary mr4"
          disabled={isSaving}
          onClick={async () => {
            setErrors({})

            setIsSaving(true)
            let resp
            if (contact) {
              resp = await putRequest(`/api/client/contacts/${contact.id}`, {
                ...form
              })
            } else {
              resp = await postRequest(`/api/client/contacts`, {
                ...form
              })
            }
            const json = await resp.json()
            setIsSaving(false)

            if (resp.status === 422) {
              setErrors(json.validation_errors)
              return
            }

            setValues({ ...initialValues })
            onSave()
            onClose()
          }}
        >
          { isSaving ? "Saving..." : "Save changes" }
        </button>
        <button onClick={onClose} className="button">Cancel</button>
      </div>
    </>
  )
}

export default ContactForm
