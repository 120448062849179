import React from 'react'
import ReactTable from 'react-table'
import Turbolinks from 'turbolinks'
import humanize from 'string-humanize'
import { format, parse as parseDate } from 'date-fns'

import { makeDefaultTableState, buildParams } from '../../helpers/table'
import { getRequest } from '../../helpers/api'

class ReferralsTable extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = makeDefaultTableState()
  }

  componentDidMount() {
    setTimeout(this.fetchData, 100)
  }

  fetchData = async () => {
    this.setState({ isLoading: true })

    const params = buildParams(this.state)
    const response = await getRequest('/api/company/referrals', params)
    const json = await response.json()
    const { data, meta } = json

    this.setState({ data, meta, isLoading: false })
  }

  render() {
    const {
      data,
      meta,
      isLoading,
      searchQuery,
      page,
      pageSize,
      minRows,
      sorted,
      filtered,
      resized,
      expanded
    } = this.state

    return (
      <>
        <div className="flex flex-column  flex-row-ns items-center-ns mb5">
          <h1 className="flex-auto page-title mb0">Referrals</h1>

          <div className="flex self-stretch items-center justify-between bg-white search-field mv4 mv0-ns">
            <label htmlFor="referralsTableSearch" className="flex items-center">
              <i className="material-icons">search</i>
            </label>

            <input
              id="referralsTableSearch"
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(event) => {
                this.setState({ searchQuery: event.target.value }, this.fetchData)
              }}
            />
          </div>
        </div>

        <div className="content-box">
          <div className="inner">
            <ReactTable
              filterable={false}
              loading={isLoading}
              data={data}
              pages={meta.total_pages}
              manual
              className="data-table"
              columns={[
                {
                  Header: 'Name',
                  accessor: 'full_name',
                },
                {
                  Header: 'Request Type',
                  accessor: 'matter',
                  Cell: (props) => <span>{humanize(props.value)}</span>
                },
                {
                  Header: 'Contact No.',
                  accessor: 'contact_number'
                },
                {
                  Header: 'Email',
                  accessor: 'email'
                },
                {
                  Header: 'Date Received',
                  accessor: 'created_at',
                  Cell: (props) => {
                    const date = parseDate(props.value)
                    return <span>{format(date, 'Do MMMM YYYY')}</span>
                  }
                }
              ]}

              page={page}
              pageSize={pageSize}
              minRows={minRows}
              sorted={sorted}
              filtered={filtered}
              resized={resized}
              expanded={expanded}

              onSortedChange={(sorted) => this.setState({ sorted }, this.fetchData)}
              onPageChange={(page) => this.setState({ page }, this.fetchData)}
              onPageSizeChange={(pageSize, page) => this.setState({ page, pageSize }, this.fetchData)}
              onExpandedChange={(expanded) => this.setState({ expanded }, this.fetchData)}
              onResizedChange={(resized) => this.setState({ resized })}
              onFilteredChange={(filtered) => this.setState({ filtered }, this.fetchData)}

              getTdProps={(state, rowInfo) => {
                return {
                  style: {
                    fontWeight: rowInfo && rowInfo.original.assigned_to ? 400 : 600
                  },
                  onClick: () => {
                    if (rowInfo) {
                      Turbolinks.visit(`/company/referrals/${rowInfo.original.id}`)
                    }
                  }
                }
              }}
            />
          </div>
        </div>
      </>
    )
  }
}

export default ReferralsTable
