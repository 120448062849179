import React from "react"
import Modal from "../Modal"

const InviteRequestStatusModal = ({ open, client, onClose }) => {
  return (
    <Modal showClose={true} title="Invite Request Sent" open={open} onClose={onClose}>
      <p>We have invited {client.first_name} to Asset Nexus. We will now set up their account. Please note, this process can take up to one working week to complete.</p>
      <p>We will notify you when this process has completed.</p>
    </Modal>
  )
}

export default InviteRequestStatusModal
