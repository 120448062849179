import React, { useState } from "react"

type Props = {
  continue_path: string,
  company_name: string,
  invited_by_name: string,
  end_user_will_path: string,
  company_logo_src: string
}

const TrustedFriendStepBlock = ({continue_path, company_name, invited_by_name, end_user_will_path, company_logo_src} : Props) => {
  const data = [
    {
      show: true,
      content: (
        <div>
          <h3>Welcome to Asset Nexus</h3>
          <p>Who are we?</p>
          <p>Asset Nexus is a cloud-based platform used by solicitors to support {invited_by_name} and his legacy by keeping connected to the parties named in his Will and his trusted friends.</p>
          <p>Asset Nexus allows {invited_by_name} to access his Will at any time at the click of a button.</p>
        </div>
      )
    },
    {
      show: false,
      content: (
        <div>
          <h3>The Role of a trusted friend</h3>
          <p>{invited_by_name} simply requests that at the time of his passing that you notify his us, his solicitor, so we can start the administration of {invited_by_name}’s  estate so his family can receive the financial support they need.</p>
          <p>Asset Nexus will allow you to notify us of {invited_by_name}’s passing at the click of a button.</p>
        </div>
      )
    },
    {
      show: false,
      content: (
        <div>
          <h3>More than Will Identification</h3>
          <p>Have you made any informed decisions and choices yet to protect you and your families future?</p>
          <p>Most people make a Will and think that’s it. Job done. But in reality, this is the start a big problem.</p>
          <p>What if our loved ones didn’t know where you have made your Will. If your loved ones cant find your Will, then intestacy laws apply, in short this means that those you intended to leave gifts for may never get those gifts.</p>
        </div>
      )
    },
    {
      show: false,
      content: (
        <div>
          <h3>Will you become a statistic?</h3>
          <p>Do your loved ones even know where all your assets; bank accounts, insurance, pension, property are? There are currently £15billion of unclaimed assets in the UK. Don’t let your assets become a statistic.</p>
          <p>You too can also benefit from our service</p>
          <div>
            <img height="100" src={company_logo_src} />
          </div>
        </div>
      )
    }
  ]

  const [steps, setSteps] = useState(data)

  const GoNext = (index) => {
    let newSteps = [...steps]
    newSteps[index].show = false
    console.log(newSteps[index+1])
    if (newSteps[index+1]) {
      newSteps[index+1].show = true
    }
    setSteps(newSteps)
  };

  return (
    <div className="step-block" >
      <a className="step-block__close" href={continue_path} ><i className="material-icons">close</i></a>

        {steps.map((step, i) => {
            let nextBtn = <a href={continue_path} className="step-block__next" >Welcome to Asset Nexus <i className="material-icons">keyboard_arrow_right</i></a>

            if (steps[i+1]) {
              nextBtn = <a href="#" className="step-block__next" onClick={e => {
                e.preventDefault()
                GoNext(i)
              }} >Next <i className="material-icons">keyboard_arrow_right</i></a>
            }

            return (
              <div className={step.show ? '' : 'dn'} key={i}>
                {step.content}
                {nextBtn}
              </div>
            )
          })}
    </div>
  )
}

export default TrustedFriendStepBlock
