import React, { useState } from "react"

type Props = {
  continue_path: string,
  company_name: string,
  invited_by_name: string,
  end_user_will_path: string,
  company_logo_src: string
}

const TrusteeStepBlock = ({continue_path, company_name, invited_by_name, end_user_will_path, company_logo_src} : Props) => {
  const data = [
    {
      show: true,
      content: (
        <div>
          <h3>You are a Trustee</h3>
          <p>{invited_by_name} recently made a Will with {company_name}.</p>
          <p>We can confirm that {invited_by_name} named you as a trustee of his Will.</p>
        </div>
      )
    },
    {
      show: false,
      content: (
        <div>
          <h3>Who are we?</h3>
          <p>Asset Nexus is a cloud-based platform used by solicitors  to support {invited_by_name} and his legacy by keeping connected to the parties named in his Will.</p>
          <p>Asset Nexus allows {invited_by_name} to access his Will at any time at the click of a button.</p>
          <p>{invited_by_name} can contact {company_name} to amend his  Will at key points throughout his lifetime should he wish to do so.</p>
        </div>
      )
    },
    {
      show: false,
      content: (
        <div>
          <h3>Trustee Responsibilities</h3>
          <p>What do you need to do?</p>
          <p>For now, not a whole lot.  Your responsibilities as trustee only begin upon {invited_by_name}’s death.</p>
          <p>However, we do advise that you familiarise yourself with the roles and responsibilities of a trustee.</p>
        </div>
      )
    },
    {
      show: false,
      content: (
        <div>
          <h3>Legal Responsibility</h3>
          <p>As a Trustee you have an important role, as you are the one who will deliver {invited_by_name}’s trust, as set out in his Will to the intended beneficiaries.</p>
          <p>This may sound relatively straightforward, but there are a number of key legal and financial responsibilities involved in this process. We want to share them with you, to bring total clarity and prepare you for what is required by this role.</p>
          <p>Our technology is available to help support you and to provide you with knowledge and insight about the role of an Executor. Our aim is to help you overcome any obstacles you might encounter during the probate process and support you practically as much as we can.</p>
        </div>
      )
    },
    {
      show: false,
      content: (
        <div>
          <h3>What happens next?</h3>
          <p>We would encourage you to familiarise yourself with the Asset Nexus platform.</p>
          <p>Particularly to what you need to do as a Trustee, so when the time comes you prepared and ready.</p>
          <p>If you would like to make a Will, need legal advice, or would like to refer our platform to another solicitor, family member or friend, you can do so once you access the platform.</p>
          <div>
            <img height="100" src={company_logo_src} />
          </div>
        </div>
      )
    }
  ]

  const [steps, setSteps] = useState(data)

  const GoNext = (index) => {
    let newSteps = [...steps]
    newSteps[index].show = false
    console.log(newSteps[index+1])
    if (newSteps[index+1]) {
      newSteps[index+1].show = true
    }
    setSteps(newSteps)
  };

  return (
    <div className="step-block" >
      <a className="step-block__close" href={continue_path} ><i className="material-icons">close</i></a>

        {steps.map((step, i) => {
            let nextBtn = <a href={continue_path} className="step-block__next" >Welcome to Asset Nexus <i className="material-icons">keyboard_arrow_right</i></a>

            if (steps[i+1]) {
              nextBtn = <a href="#" className="step-block__next" onClick={e => {
                e.preventDefault()
                GoNext(i)
              }} >Next <i className="material-icons">keyboard_arrow_right</i></a>
            }

            return (
              <div className={step.show ? '' : 'dn'} key={i}>
                {step.content}
                {nextBtn}
              </div>
            )
          })}
    </div>
  )
}

export default TrusteeStepBlock
