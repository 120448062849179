import React, { useState, useEffect } from "react";
import { Col, Grid, Row } from "react-flexbox-grid";
import LineChart from "./linechart/linechart";
import SelectBox from "./linechart/selectbox";
import SelectButton from "./linechart/selectbutton";
import { getRequest } from "../../helpers/api";
import Loading from "../Loading";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { IoUmbrellaOutline, IoHomeOutline } from "react-icons/io5";
import { RiMoneyPoundCircleLine, RiMoneyPoundBoxLine } from "react-icons/ri";
import LineChartNPV from "./linechart/linechartNPV";

const colorRange = [
  "#264653ff",
  "#2a9d8fff",
  "#e9c46aff",
  "#f4a261ff",
  "#e76f51ff",
];

function Timeline() {
  const [dataSelection, setChildData] = useState("")
  const [TextTest, setTextTest] = useState("")
  let [LifeDeathToggle, setToggle] = useState(false)
  let [apiData, setApiData] = useState(null)

  if (apiData) {
    if (LifeDeathToggle === true) {
      apiData.statsTotals[0].NPV[0].value =
        apiData.statsTotals[0].Assets.value - apiData.statsTotals[0].Debts.value;
    } else {
      apiData.statsTotals[0].NPV[0].value =
        apiData.statsTotals[0].Assets.value +
        apiData.statsTotals[0].Insurance.value -
        apiData.statsTotals[0].Debts.value;
    }  
  }

  useEffect(() => {
    async function getData() {
      const [statsTotals, timelineData] = await Promise.all([
        getRequest("/api/assets/stats", { type: 'totals' }).then(value => value.json()),
        getRequest("/api/client/timeline", { v2: 'true' }).then(value => value.json()),
      ])
      setApiData({ statsTotals: statsTotals, timelineData: timelineData })
    }
    getData()
  }, [])

  if (!apiData) return <Loading wrapperClassName="mv6 justify-center" label="Loading Timeline..." />

  // console.log(apiData)

  return (
    <>
      <div className="timelineChart">
        <Tabs>
          <TabList>
            <Tab onClick={() => setChildData("All")}>
              <div className="tab-icon">
                <RiMoneyPoundCircleLine />
              </div>
              NPV
            </Tab>
            <Tab onClick={() => setChildData("All")}>
              <div className="tab-icon">
                <IoHomeOutline />{" "}
              </div>
              Assets
            </Tab>
            <Tab onClick={() => setChildData("All")}>
              <div className="tab-icon">
                <RiMoneyPoundBoxLine />
              </div>
              Debts
            </Tab>
            <Tab onClick={() => setChildData("All")}>
              <div className="tab-icon">
                <IoUmbrellaOutline />
              </div>
              Insurance
            </Tab>
          </TabList>

          <TabPanel>
            <Grid fluid>
              <Row center="xs">
                <Col xs={12} sm={12} md={12} lg={12} className="u-pt-gi">
                  <div className="tg-list">
                    <input id="cb1" className="tgl tgl-light" type="checkbox" />
                    Life{" "}
                    <label
                      className="tgl-btn"
                      htmlFor="cb1"
                      onClick={() =>
                        setToggle((LifeDeathToggle = !LifeDeathToggle))
                      }
                    ></label>{" "}
                    Death
                  </div>

                  <LineChartNPV
                    ChartData={apiData.statsTotals}
                    dataSelection={dataSelection}
                    dataHidden={TextTest}
                    colorRange={colorRange}
                  />
                </Col>
              </Row>
            </Grid>
          </TabPanel>
          <TabPanel>
            <Grid fluid>
              <Row center="xs">
                <Col xs={12} sm={12} md={12} lg={12} className="u-pt-gi">
                  <Row center="xs">
                    <Grid>
                      <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className="u-pt-gi">
                          <Row start="md" center="xs">
                            <SelectBox
                              ChartData={apiData.timelineData['Assets']}
                              passChildData={setChildData}
                            />
                          </Row>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className="u-pt-gi">
                          <Row end="md" center="xs">
                            <SelectButton
                              ChartData={apiData.timelineData['Assets']}
                              dataSelection={dataSelection}
                              passTextUp={setTextTest}
                              colorRange={colorRange}
                            />
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <LineChart
                            ChartData={apiData.timelineData['Assets']}
                            dataSelection={dataSelection}
                            dataHidden={TextTest}
                            colorRange={colorRange}
                          />
                        </Col>
                      </Row>
                    </Grid>
                  </Row>
                </Col>
              </Row>
            </Grid>
          </TabPanel>
          <TabPanel>
            <Grid fluid>
              <Row center="xs">
                <Col xs={12} sm={12} md={12} lg={12} className="u-pt-gi">
                  <Row center="xs">
                    <Grid>
                      <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className="u-pt-gi">
                          <Row start="md" center="xs">
                            <SelectBox
                              ChartData={apiData.timelineData['Debts']}
                              passChildData={setChildData}
                            />
                          </Row>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className="u-pt-gi">
                          <Row end="md" center="xs">
                            <SelectButton
                              ChartData={apiData.timelineData['Debts']}
                              dataSelection={dataSelection}
                              passTextUp={setTextTest}
                              colorRange={colorRange}
                            />
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <LineChart
                            ChartData={apiData.timelineData['Debts']}
                            dataSelection={dataSelection}
                            dataHidden={TextTest}
                            colorRange={colorRange}
                          />
                        </Col>
                      </Row>
                    </Grid>
                  </Row>
                </Col>
              </Row>
            </Grid>
          </TabPanel>
          <TabPanel>
            <Grid fluid>
              <Row center="xs">
                <Col xs={12} sm={12} md={12} lg={12} className="u-pt-gi">
                  <Row center="xs">
                    <Grid>
                      <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className="u-pt-gi">
                          <Row start="md" center="xs">
                            <SelectBox
                              ChartData={apiData.timelineData['Insurance']}
                              passChildData={setChildData}
                            />
                          </Row>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className="u-pt-gi">
                          <Row end="md" center="xs">
                            <SelectButton
                              ChartData={apiData.timelineData['Insurance']}
                              dataSelection={dataSelection}
                              passTextUp={setTextTest}
                              colorRange={colorRange}
                            />
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <LineChart
                            ChartData={apiData.timelineData['Insurance']}
                            dataSelection={dataSelection}
                            dataHidden={TextTest}
                            colorRange={colorRange}
                          />
                        </Col>
                      </Row>
                    </Grid>
                  </Row>
                </Col>
              </Row>
            </Grid>
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
}

export default Timeline;