import React from 'react'
import ActionCable from 'actioncable'
import $ from 'jquery'
import firebase from 'firebase'
import { v4 as uuidv4 } from 'uuid'
import { postRequest } from "../helpers/api"
import ChatLine from './ChatLine'

interface Props {
  currentUser: any
  partner: any
  oauth_client_id: string
  oauth_redirect_url: string
  api_ws_root: string
  attach_symbol: string
  firebase_api_key: string
  firebase_auth_domain: string
  firebase_database_url: string
  firebase_project_id: string
  firebase_storage_bucket: string
  firebase_messaging_sender_id: string
  firebase_app_id: string
}

interface State {
  input_idle: string
  access_token: string
  refresh_token: string
  from_date: string
  chat_lines: any
  is_loading_chat: boolean
  is_sending_chat: boolean
  file_to_upload: any
}

export default class ChatInterface extends React.Component<Props, State> {
  private inputRef = React.createRef<HTMLInputElement>()
  private storageRef : any

  constructor(props) {
    super(props)
    this.state = {
      input_idle: '',
      access_token: '',
      refresh_token: '',
      from_date: '',
      chat_lines: [],
      is_loading_chat: true,
      is_sending_chat: false,
      file_to_upload: null,
    }
  }

  private cable = ActionCable.createConsumer(this.props.api_ws_root)
  private oAuthAccessToken = ''
  private oAuthRefreshToken = ''

  componentDidMount() {
    const { currentUser, partner, oauth_client_id, oauth_redirect_url } = this.props

    console.log('componentDidMount')
    this.setState({input_idle: ''})

    console.log(currentUser)
    console.log(partner)

    let oAuthEmail = localStorage.getItem('oAuthEmail')
    let oAuthAccessToken = ''
    let oAuthRefreshToken = ''
    localStorage.setItem('partner', partner.id)
    localStorage.setItem('returnUrl', window.location.href)
    if (oAuthEmail !== currentUser.email) {
      console.log('email diff, clearing previous oAuth token')
      localStorage.setItem('oAuthEmail', currentUser.email)
      localStorage.setItem('oAuthAccessToken', '')
      oAuthEmail = currentUser.email
    }
    oAuthAccessToken = localStorage.getItem('oAuthAccessToken')
    oAuthRefreshToken = localStorage.getItem('oAuthRefreshToken')
    console.log('saved email = ' + oAuthEmail)
    console.log('saved token = ' + oAuthAccessToken)
    if (oAuthAccessToken === '' || oAuthAccessToken === null) {
      this.setState({access_token: ''})
      console.log('need to get new oAuth token')
      let url = 'https://zoom.us/oauth/authorize?response_type=code&client_id=' + oauth_client_id + '&redirect_uri=' + oauth_redirect_url
      console.log(url)
      //alert(url)
      window.location.href = url
    }
    this.setState({
      access_token: oAuthAccessToken,
      refresh_token: oAuthRefreshToken
    })

    this.cable.subscriptions.create({channel: "ChatChannel", room: currentUser.email + " with " + partner.email}, {
      connected() {
        console.log('connected')
      },
      disconnected() {
        console.log('disconnected')
      },
      received: function(data) {
        console.log('recv : ' + data["data"])
        this.updateChatLine(data, this.componentRef)
      },
      speak(message) {
        console.log('speak')
        return this.perform('speak' , {
          message: message
        })
      },
      updateChatLine: this.handleRecvChatLine,
      componentRef: this,
    })
    
    setTimeout(this.fetchChatList, 100)

    window.addEventListener('resize', this.handleWindowResize)

    if (!firebase.apps.length) {
      const firebaseConfig = {
        apiKey: this.props.firebase_api_key,
        authDomain: this.props.firebase_auth_domain,
        databaseURL: this.props.firebase_database_url,
        projectId: this.props.firebase_project_id,
        storageBucket: this.props.firebase_storage_bucket,
        messagingSenderId: this.props.firebase_messaging_sender_id,
        appId: this.props.firebase_app_id,
      };
      console.log(firebaseConfig)
      firebase.initializeApp(firebaseConfig)
    }
    this.storageRef = firebase.storage().ref()
    console.log(this.storageRef)  
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize)
  }

  componentDidUpdate() {
    this.handleWindowResize()
  }

  handleWindowResize = () => {
    let chatSection = $('#chat-section')[0]
    let chatInput = $('.chat-input-box')[0]
    let rect = chatSection.getBoundingClientRect()
    let height = window.innerHeight - chatInput.clientHeight - rect.top - 96
    chatSection.style.height = '' + height + 'px'
  }

  scrollChatTop() {
    let chatSection = $('#chat-section')[0]
    chatSection.scrollTop = 0
  }

  scrollChatBottom() {
    let chatSection = $('#chat-section')[0]
    chatSection.scrollTop = chatSection.scrollHeight
  }

  fetchChatList = async () => {
    this.setState({
      is_loading_chat: true
    })
    const { currentUser, partner } = this.props
    const params = {
      zoom_method: "get",
      zoom_api: "/chat/users/me/messages",
      zoom_access_token: this.state.access_token,
      zoom_refresh_token: this.state.refresh_token,
      zoom_params: {
        to_contact: partner.email
      },
      from_date: this.state.from_date,
    }
    const resp = await postRequest("/zoom", params)
    const json = await resp.json()
    console.log(json)
    this.handleTokenUpdate(json)
    if (json["result"] === "ok") {
      const data = json["data"]
      const chat_lines = data
      const from_date = json["from_date"]
      console.log(from_date)
      console.log(chat_lines)
      let init_load = false
      if (this.state.chat_lines.length === 0) {
        init_load = true
      }
      let total_chat_lines = [...this.state.chat_lines, ...chat_lines]
      total_chat_lines.concat(chat_lines)
      total_chat_lines.sort(this.chatLineSortFunc)
      this.setState({
        is_loading_chat: false,
        from_date: from_date,
        chat_lines: total_chat_lines,
      })
      if (init_load) {
        this.scrollChatBottom()
      } else {
        this.scrollChatTop()
      }
    }
  }

  chatLineSortFunc = (a, b) => {
    if (a.timestamp < b.timestamp) {
      return -1
    } else if (a.timestamp == b.timestamp) {
      return 0
    } else {
      return 1
    }
  }

  handleTokenUpdate(json) {
    if (json["result"] === "token_error") {
      console.log('token error : ' + json["data"])
      localStorage.removeItem('oAuthAccessToken')
      localStorage.removeItem('oAuthRefreshToken')
      alert('Zoom OAuth is expired, please refresh this page to continue.')
    } else if (json["result"] === "ok") {
      const zoom_new_access_token = json["zoom_new_access_token"]
      const zoom_new_refresh_token = json["zoom_new_refresh_token"]
      if (zoom_new_access_token !== undefined && zoom_new_access_token !== '' && zoom_new_refresh_token !== undefined && zoom_new_refresh_token !== '') {
        console.log('token updated')
        localStorage.setItem('oAuthAccessToken', zoom_new_access_token)
        localStorage.setItem('oAuthRefreshToken', zoom_new_refresh_token)
      }
    }
  }

  handleRecvChatLine(data, componentRef) {
    let chat_line = data
    let chat_lines = [...componentRef.state.chat_lines, chat_line]
    chat_lines.sort(this.chatLineSortFunc)
    componentRef.setState({
      chat_lines: chat_lines
    })
    componentRef.scrollChatBottom()
  }

  handleInputChange(e) {
    if (e.target.id === "input-idle") {
      this.setState({input_idle: e.target.value})
    }
  }

  handleButtonClick(e) {
    if (e.target.id === "btn-send") {
      this.handleSendChat()
    } else if (e.target.id === "chat-load-more") {
      this.fetchChatList()
    }
  }

  handleFileSelect(e) {
    console.log('handleFileSelect')
    console.log(e.target.files)
    const file = e.target.files[0]
    const fileName = file.name
    $('#input-filename')[0].textContent = fileName
    this.setState({
      file_to_upload: file
    })
  }

  cleanFileSelect() {
    console.log('cleanFileSelect')
    $('#input-filename')[0].textContent = ''
    $('#upload-status')[0].textContent = ''
    this.setState({
      is_sending_chat: true, 
      file_to_upload: null
    })
  }

  uploadFile = async(componentRef) => {
    const { currentUser, attach_symbol } = this.props
    this.setState({
      is_sending_chat: true
    })
    const file = this.state.file_to_upload
    console.log(file)
    var uploadTask = this.storageRef.child(currentUser.email + '/' + uuidv4() + '/' + file.name).put(file)
    uploadTask.on('state_changed', function(snapshot){
      var progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
      $('#upload-status')[0].textContent = progress + '% ...'
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          console.log('Upload is paused')
          break
        case firebase.storage.TaskState.RUNNING: // or 'running'
          console.log('Upload is running')
          break
      }
    }, function(error) {
      console.log('upload error')
      console.log(error)
      componentRef.cleanFileSelect()
    }, function() {
      console.log('upload success')
      componentRef.cleanFileSelect()
      uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
        let urlWrap = attach_symbol + uploadTask.snapshot.ref.name + ":" + downloadURL
        console.log(urlWrap)
        console.log(componentRef)
        componentRef.sendChat(urlWrap)
        console.log('done')
        const message = componentRef.state.input_idle
        if (message) {
          console.log('sending message')
          componentRef.setState({
            is_sending_chat: true,
            input_idle: '',
          })
          componentRef.inputRef.current.focus()
          componentRef.sendChat(message)
        }
      })
    })
  }

  sendChat = async (message) => {
    const { partner } = this.props
    if (message === '') {
      console.log('nothing to send')
      return
    }
    console.log('send : ' + message)
    const params = {
      zoom_method: "post",
      zoom_api: "/chat/users/me/messages",
      zoom_access_token: this.state.access_token,
      zoom_refresh_token: this.state.refresh_token,
      zoom_params: {
        message: message,
        to_contact: partner.email
      }
    }
    const resp = await postRequest("/zoom", params)
    const json = await resp.json()
    console.log(json)
    this.handleTokenUpdate(json)
    this.setState({
      is_sending_chat: false
    })

    if (json["result"] === "ok") {
      const data = json["data"]
      const chat_line = data
      console.log(chat_line)
      this.setState({
        chat_lines: [...this.state.chat_lines, chat_line]
      })
      this.scrollChatBottom()
    }
  }

  handleSendChat = async () => {
    const message = this.state.input_idle
    if (this.state.is_sending_chat) {
      console.log('waiting for previous send done')
      return
    }
    if (this.state.file_to_upload) {
      console.log('need to upload file first')
      await this.uploadFile(this)
    } else {
      this.setState({
        is_sending_chat: true,
        input_idle: '',
      })
      this.inputRef.current.focus()
      this.sendChat(message)
    }
  }

  render() {
    const { currentUser, partner, attach_symbol } = this.props
    let chatStatus : any
    let chatSection : any

    if (this.state.is_loading_chat) {
      chatStatus = <div className="center-align" id="chat-loading">Loading messages...</div>
    } else {
      chatStatus = <div className="center-align" id="chat-load-more" onClick={e => this.handleButtonClick(e)}>Load more</div>
    }
    chatSection = this.state.chat_lines.map((data, index) => {
      let name = ''
      let align = ''
      let date = ''
      let splitter : any
      if (data.sender === partner.email) {
        name = partner.first_name
        align = 'left'
      } else if (data.sender === currentUser.email) {
        name = currentUser.first_name
        align = 'right'
      } else {
        name = currentUser.first_name
        align = 'right'
      }

      let ms_for_one_day = 86400000
      let curDay = Math.floor(data.timestamp / ms_for_one_day)
      let prevDay = 0
      console.log()
      if (index !== 0) {
        prevDay = Math.floor(this.state.chat_lines[index - 1].timestamp / ms_for_one_day)
        if (prevDay < curDay) {
          let unix_timestamp = curDay * ms_for_one_day
          let datetime = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(unix_timestamp)
          if (Date.now() - curDay * ms_for_one_day < ms_for_one_day) {
            datetime = 'Today'
          }
          splitter = <div className="chatline-split">{datetime}</div>
        }
      }

      return (<div key={data.timestamp}>
                {splitter}
                <ChatLine 
                  key={partner.first_name + data.timestamp}
                  name={name}
                  align={align}
                  timestamp={data.timestamp}
                  type={data.type}
                  data={data.data}
                  attach_symbol={attach_symbol} />
        </div>
      )
    })

    return (
      <div className="chat-containter">
        <h1 className="page-title center-align">Conversation with {partner.first_name + " " + partner.last_name}</h1>
        <div className="chat-section" id="chat-section">
          {chatStatus}
          {chatSection}
        </div>
        <div className="form-field chat-input-box">
          <label htmlFor="input-attach">
            <div className="input-file-box">
              <i className="material-icons">attach_file</i>
              <div id="input-filename"></div>
              <div id="upload-status"></div>
            </div>
          </label>
          <input type='file' id="input-attach" onChange={e => this.handleFileSelect(e)}/>
          <input type="text" id="input-idle" ref={this.inputRef} value={this.state.input_idle} onKeyPress={(e) => { e.key === "Enter" ? this.handleSendChat() : '' }} onChange={e => this.handleInputChange(e)}/>
          <button id="btn-send" onClick={e => this.handleButtonClick(e)}>
            Send
          </button>
        </div>
      </div>
    )
  }
}
