import React, { useState } from "react"
import Modal from "./Modal"

const TermsModal = () => {
  const [showTermsModal, setTermsModal] = useState(false)

  return (
    <div>
      I consent to the
      <a href="#"
        style={{ marginLeft: '5px' }}
        className="button-link"
        onClick={e => {
          e.preventDefault()
          setTermsModal(true)
        }}
      >Terms and Conditions</a>

      <Modal title="Terms &amp; Conditions" onClose={() => setTermsModal(false)} open={showTermsModal} showClose={true} fullWidth={true}>
        <iframe src="https://assetnexus.io/terms-conditions" width="100%" height="5000" frameBorder="0" ></iframe>
      </Modal>
    </div>
  )
}

export default TermsModal
