import React  from 'react'
import { find } from 'lodash'

import { postRequest } from '../../../helpers/api'

import BackArrow from '../../BackArrow'
import MoneyInput from '../../inputs/MoneyInput'
import DateInput from "../../inputs/DateInput"
import { format as formatDate } from "date-fns"

interface FormFields {
  current_value: any
  account_reference_number: string
  provider_name: string
  monthly_payment: any
  start_date: string
}

interface FormError {
  field: string
  errors: string[]
}

interface Props {
  hideTitle?: boolean
  onBack(): void
  onSuccess(): void
}

interface State {
  isSaving: boolean
  errors: FormError[]
  form: FormFields
}

export default class StudentLoan extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      isSaving: false,
      errors: [],
      form: {
        current_value: '',
        account_reference_number: '',
        provider_name: '',
        monthly_payment: '',
        start_date: '',
      }
    }
  }

  setFormValues(values: FormFields) {
    this.setState({ form: values })
  }

  onSubmit = async (event) => {
    event.preventDefault()

    const { onSuccess } = this.props
    const { form, isSaving } = this.state

    if (isSaving) return

    this.setState({ isSaving: true })

    const response = await postRequest(`/api/assets`, {
      asset_category: 'student_loan',
      ...form
    })

    const json = await response.json()

    if (response.status === 201) {
      onSuccess()
    } else {
      this.setState({ errors: json.validation_errors })
    }

    this.setState({ isSaving: false })
  }

  renderHeading() {
    const { hideTitle, onBack } = this.props

    if (hideTitle) return

    return (
      <>
        <BackArrow onClick={onBack} />
        <h1 className="page-title">Add a student loan</h1>
      </>
    )
  }

  renderFieldErrors(field: string, errors: FormError[]) {
    const fieldErrors = find(errors, row => row.field === field)

    if (fieldErrors) {
      return <span className="hint -error">{fieldErrors.errors.join(', ')}</span>
    }
  }

  render() {
    const { onBack } = this.props
    const { form, errors, isSaving } = this.state

    return (
      <>
        {this.renderHeading()}

        <form onSubmit={this.onSubmit}>
          <div className="form-field -maxlength">
            <label htmlFor="account_reference_number">Account reference number</label>

            <input
              type="text"
              id="account_reference_number"
              value={form.account_reference_number}
              onChange={(event) => {
                this.setFormValues({
                  ...form,
                  account_reference_number: event.target.value
                })
              }}
            />

            {this.renderFieldErrors('account_reference_number', errors)}
          </div>

          <div className="form-field -maxlength">
            <label htmlFor="provider_name">Provider name</label>

            <input
              type="text"
              id="provider_name"
              value={form.provider_name}
              onChange={(event) => {
                this.setFormValues({
                  ...form,
                  provider_name: event.target.value
                })
              }}
            />

            {this.renderFieldErrors('provider_name', errors)}
          </div>


          <div className="form-field -maxlength">
            <label htmlFor="start_date">Start date</label>
            <DateInput value={form.start_date || ""} onDayChange={date => this.setFormValues({ ...form, start_date: formatDate(date, "YYYY-MM-DD") })} />
            
            {this.renderFieldErrors('start_date', errors)}
          </div>

          <div className="form-field -maxlength">
            <label htmlFor="current_value">Loan amount</label>

            <MoneyInput
              id="current_value"
              defaultValue={form.current_value}
              onChange={(current_value) => {
                this.setFormValues({ ...form, current_value })
              }}
            />

            {this.renderFieldErrors('current_value', errors)}
          </div>

          <div className="form-field -maxlength">
            <label htmlFor="monthly_payment">Monthly payment</label>

            <MoneyInput
              id="monthly_payment"
              defaultValue={form.monthly_payment}
              onChange={(monthly_payment) => {
                this.setFormValues({ ...form, monthly_payment })
              }}
            />

            {this.renderFieldErrors('monthly_payment', errors)}
          </div>

          <div className="flex items-center">
            <button
              type="submit"
              className="button -primary mr3"
              disabled={isSaving}
              children={isSaving ? 'Saving...' : 'Add loan'}
            />

            <button
              type="button"
              className="button"
              disabled={isSaving}
              onClick={onBack}
              children="Cancel"
            />
          </div>
        </form>
      </>
    )
  }
}
