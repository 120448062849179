import React, { useState } from "react"
import BellIcon from "../../assets/images/icons/bell_light-blue.svg"

const UpdatesMenuItem = () => {

  return (
    
    <div className="user-navigation__updates" >
    <a href="#">
      <img width="22" height="22" src={BellIcon} />
    </a>
    </div>
  )
}

export default UpdatesMenuItem
