import React from "react"
import humanize from "string-humanize"
import { parse as parseDate, format } from "date-fns"
import { connect } from "react-refetch"

import { postRequest, deleteRequest } from "../../helpers/api"

import Loading from "../Loading"
import MoreMenu from "../MoreMenu"

export const UsersTable = ({ currentUser, currentRole, assignableRoles, usersFetch, onEdit, onUpdate, usersCount }) => {
  if (usersFetch.pending) return <Loading label="Fetching staff accounts" />
  if (usersFetch.error) return <div>Error fetching users.</div>

  usersCount(usersFetch.value.data.length);

  return (
    <div className="table-wrapper">
      <table className="static-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Last Login</th>
            <th>Date created</th>
            <th>Video Call</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {usersFetch.value.data.map(user => (
            <tr key={user.id}>
              <td>{user.full_name}</td>
              <td>
                <a target="_blank" href={`mailto:${user.email}`}>{user.email}</a>
              </td>
              <td>{humanize(user.role)}</td>
              <td>
                {user.last_sign_in_at ?
                  <React.Fragment>
                    {format(parseDate(user.last_sign_in_at), "Do MMM YYYY")}
                  </React.Fragment>
                  :
                  <span className="light-silver">Hasn't signed in yet</span>
                }
              </td>
              <td>{format(parseDate(user.created_at), "Do MMM YYYY")}</td>
              <td className="center-td">
                <a href="#" className="center-td" onClick={async e => {
                  e.preventDefault()
                  if (user.id === currentUser.id) {
                    alert('You need to select different user to start the video call.')
                    return
                  }
                  const res = await postRequest(`/zoom_meeting`, {
                    email: currentUser.email,
                    first: currentUser.first_name,
                    last: currentUser.last_name,
                    partner: user.first_name + " " + user.last_name,
                  })
                  //console.log(res)
                  const json = await res.json()
                  console.log(json)

                  if (res.status === 201) {
                    console.log("success")
                    console.log("result = " + json.result)
                    if (json.result == "activate") {
                      alert(json.message)
                    } else if (json.result == "done") {
                      alert(json.message)
                      window.location = json.start_url
                    }
                  } else {
                    console.log("fail")
                  }
                }}><i className="material-icons">desktop_windows</i></a>
              </td>
              <td>
                {(currentRole !== "user" && assignableRoles.includes(user.role)) &&
                  <MoreMenu>
                    {({ close }) => (
                      <React.Fragment>
                        <a
                          href="#"
                          className="db near-black no-underline dim fw6 f6 mb3"
                          onClick={e => {
                            e.preventDefault()
                            onEdit(user)
                            close()
                          }}
                        >
                          Edit
                        </a>
                        <a
                          href="#"
                          className="db near-black no-underline dim fw6 f6"
                          onClick={async e => {
                            e.preventDefault()
                            close()
                            if (!confirm("Are you sure?")) return
                            await deleteRequest(`/api/company/users/${user.id}`)
                            onUpdate()
                          }}
                        >
                          Delete
                        </a>
                      </React.Fragment>
                    )}
                  </MoreMenu>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default connect(({ version }) => ({
  usersFetch: {
    url: `/api/company/users`,
    comparison: `users:${version}`
  }
}))(UsersTable)
