import React, { useState, useReducer } from "react"
import UsersTable from "./UsersTable"
import PrimaryCTA from "../PrimaryCTA"
import Modal from "../Modal"
import UserForm from "./UserForm"

export default ({ currentUser, currentRole, assignableRoles }) => {
  const [showAddModal, setShowAddModal] = useState(false)
  const [editing, setEditing] = useState(null)
  const [usersCount, setUsersCount] = useState(0)
  const [version, incrementVersion] = useReducer(state => state + 1, 0)

  return (
    <div>
      <PrimaryCTA id="settings-primary-cta">
        <button className="button -primary" onClick={() => setShowAddModal(true)}>
          Add user
        </button>
      </PrimaryCTA>

      <div className="content-box">
        <div className="inner">
          <UsersTable
            version={version}
            onEdit={setEditing}
            onUpdate={() => incrementVersion()}
            currentUser={currentUser}
            currentRole={currentRole}
            assignableRoles={assignableRoles}
            usersCount={(count) => setUsersCount(count)}
          />
        </div>
      </div>

      <Modal title="Add new user" open={showAddModal} onClose={() => setShowAddModal(false)}>
        <UserForm
          new={true}
          onCancel={() => setShowAddModal(false)}
          assignableRoles={assignableRoles}
          onSave={() => {
            setShowAddModal(false)
            incrementVersion()
          }}
        />
      </Modal>

      <Modal title="Update user" open={!!editing} onClose={() => setEditing(null)}>
        {editing &&
          <UserForm
            new={false}
            existing={editing}
            onCancel={() => setEditing(null)}
            assignableRoles={assignableRoles}
            onSave={() => {
              setEditing(null)
              incrementVersion()
            }}
          />
        }
      </Modal>
    </div>
  )
}
