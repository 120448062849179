import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Sunburst from "./sunburst";
import { Col, Grid, Row } from "react-flexbox-grid";
import Loading from "../Loading";
import { getRequest } from "../../helpers/api";

import { IoUmbrellaOutline, IoHomeOutline } from "react-icons/io5";
import { RiMoneyPoundCircleLine, RiMoneyPoundBoxLine } from "react-icons/ri";

let colorPositive = ["#2075D3", "#4F90DC", "#7BACE5", "#BDD6F2"];
let colorNegative = ["#931E66", "#9E3676", "#BE79A4", "#DFBBD0"];
let colorArrayAssets = ["#52BC46", "#64C35A", "#98D791", "#CBEBC8"];
let colorArrayDebts = colorNegative;

var formatter = new Intl.NumberFormat(undefined, {
  style: "currency",
  currency: "GBP",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

let NPVcolor = colorArrayAssets[0];

function Overview() {
  let [LifeDeathToggle, setToggle] = useState(false);
  let [apiData, setApiData] = useState(null)

  useEffect(() => {
    async function getData() {
      const [assetsData, debtsData, insurancesData, totalsData] = await Promise.all([
        getRequest("/api/assets/stats", { type: 'assets' }).then(value => value.json()),
        getRequest("/api/assets/stats", { type: 'debts' }).then(value => value.json()),
        getRequest("/api/assets/stats", { type: 'insurances' }).then(value => value.json()),
        getRequest("/api/assets/stats", { type: 'totals' }).then(value => value.json()),
      ])
      setApiData({ assetsData: assetsData, debtsData: debtsData, insurancesData: insurancesData, totalsData: totalsData })
    }
    getData()
  }, [])

  let NPVDataValue = null

  if (apiData) {
    NPVDataValue = apiData.totalsData[0].NPV[0].value;

    if (LifeDeathToggle === true) {
      NPVDataValue = apiData.totalsData[0].Assets.value - apiData.totalsData[0].Debts.value;
    } else {
      NPVDataValue =
      apiData.totalsData[0].Assets.value + apiData.totalsData[0].Insurance.value - apiData.totalsData[0].Debts.value;
    }
  
    if (NPVDataValue >= 0) {
      NPVcolor = colorArrayAssets[1];
    } else {
      NPVcolor = colorNegative[0];
    }
  }

  if (!apiData) return <Loading wrapperClassName="mv6 justify-center" label="Loading Overview..." />

  return (
    <>
      <Tabs>
        <TabList>
          <Tab>
            <div className="tab-icon">
              <RiMoneyPoundCircleLine />
            </div>
            NPV
          </Tab>
          <Tab>
            <div className="tab-icon">
              <IoHomeOutline />{" "}
            </div>
            Assets
          </Tab>
          <Tab>
            <div className="tab-icon">
              <RiMoneyPoundBoxLine />
            </div>
            Debts
          </Tab>
          <Tab>
            <div className="tab-icon">
              <IoUmbrellaOutline />
            </div>
            Insurance
          </Tab>
        </TabList>

        <TabPanel>
          <Grid fluid>
            <Row center="xs">
              <Col xs={12} sm={12} md={8} lg={8} className="u-pt-gi">
                <div className="tg-list">
                  <input id="cb1" className="tgl tgl-light" type="checkbox" />
                  Life{" "}
                  <label
                    className="tgl-btn"
                    htmlFor="cb1"
                    onClick={() =>
                      setToggle((LifeDeathToggle = !LifeDeathToggle))
                    }
                  ></label>{" "}
                  Death
                </div>
              </Col>
              <Col xs={12} sm={12} md={8} lg={8}>
                <Row center="xs">
                  <div
                    className="metric-bubble"
                    style={{ background: NPVcolor }}
                  >
                    <h4>Net Present Value</h4>
                    <h2> {formatter.format(NPVDataValue)}</h2>
                  </div>
                </Row>
              </Col>
            </Row>
          </Grid>
        </TabPanel>
        <TabPanel>
          <Grid fluid>
            <Row center="xs">
              <Col xs={12} sm={12} md={8} lg={8} className="u-pt-gi">
                <Row center="xs">
                  <Sunburst
                    ChartData={apiData.assetsData}
                    colorsArray={colorArrayAssets}
                  />
                </Row>
              </Col>
            </Row>
          </Grid>
        </TabPanel>
        <TabPanel>
          <Grid fluid>
            <Row center="xs">
              <Col xs={12} sm={12} md={8} lg={8} className="u-pt-gi">
                <Row center="xs">
                  <Sunburst
                    ChartData={apiData.debtsData}
                    colorsArray={colorArrayDebts}
                  />
                </Row>
              </Col>
            </Row>
          </Grid>
        </TabPanel>
        <TabPanel>
          <Grid fluid>
            <Row center="xs">
              <Col xs={12} sm={12} md={8} lg={8} className="u-pt-gi">
                <Row center="xs">
                  <Sunburst
                    ChartData={apiData.insurancesData}
                    colorsArray={colorPositive}
                  />
                </Row>
              </Col>
            </Row>
          </Grid>
        </TabPanel>
      </Tabs>
    </>
  );
}

export default Overview;