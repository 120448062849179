import React from "react"
import ReactTable from "react-table"
import { parse as parseDate, format } from "date-fns"
import { makeDefaultTableState, buildParams } from "../../helpers/table"
import { getRequest } from "../../helpers/api"
import { thisTypeAnnotation } from "@babel/types"

export default class ClientsTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = makeDefaultTableState({
      pageSize: 10
    })
  }

  componentDidMount() {
    setTimeout(this.fetchData, 100)
  }

  fetchData = async () => {
    this.setState({ isLoading: true })

    const params = buildParams(this.state)
    const resp = await getRequest("/api/company/clients", params)
    const json = await resp.json()
    const { data, meta } = json

    this.setState({ data, meta, isLoading: false })
  }

  filterByStatus = (value) => {
    let filtered = []
    if (value != "") {
      filtered = [{ id: "living", value }]
    }
    this.setState({ filtered }, this.fetchData)
  }

  render() {
    const { newPath, newText } = this.props
    const { data, meta, isLoading, searchQuery, page, pageSize, minRows, sorted, filtered, resized, expanded } = this.state
    const livingFilter = filtered.find(f => f.id === "living")
    return (
      <div>
        <div className="flex flex-column flex-row-ns items-center-ns mb5">
          <h1 className="flex-auto page-title mb0">All clients</h1>
          {/* <div className="flex self-stretch items-center justify-between bg-white">
            <select id="status">
              <option value="">All clients</option>
              <option value="will">Will</option>
              <option value="probate">Probate</option>
            </select>
          </div> */}
          <div className="flex self-stretch items-center justify-between bg-white ml4-ns mt4 mt0-ns search-field">
            <label htmlFor="client-keyword-search" className="flex items-center"><i className="material-icons">search</i></label>
            <input id="client-keyword-search" type="text" placeholder="Keywords" value={searchQuery} onChange={e => this.setState({ searchQuery: e.target.value }, this.fetchData)} />
          </div>
          <a href={newPath} className="button -with-icon -primary ml4-ns mt4 mt0-ns">
            <i className="material-icons">add_circle</i>
            {newText}
          </a>
        </div>
        <div className="content-box">
          <div className="inner">
            <ReactTable
              filterable={false}
              // onFetchData={this.fetchData}
              loading={isLoading}
              data={data}
              pages={meta.total_pages}
              manual // Forces table not to paginate or sort automatically, so we can handle it server-side
              className="data-table"
              columns={[
                {
                  Header: "Client reference",
                  accessor: "ref",
                  width: 150,
                },
                {
                  Header: "Name",
                  accessor: "last_name",
                  width: 180,
                  Cell: props => {
                    return props.original.full_name
                  }
                },
                {
                  Header: "Date of birth",
                  accessor: "date_of_birth",
                  width: 180,
                  Cell: props => {
                    if (props.value) {
                      const date = parseDate(props.value)
                      return <span>{format(date, "Do MMMM YY")}</span>
                    }
                    return null
                  }
                },
                {
                  Header: "Status",
                  accessor: "living",
                  sortable: false,
                  filterable: true,
                  Cell: props => {
                    if (props.value) {
                      return <span>Will</span>
                    }
                    return <span>Probate</span>
                  },
                  Filter: _cellInfo => (
                    <select onChange={event => this.filterByStatus(event.target.value)} value={(livingFilter && livingFilter.value) || ""}>
                      <option value="">All</option>
                      <option value="will">Will</option>
                      <option value="probate">Probate</option>
                    </select>
                  )
                },
                {
                  Header: "Next Review",
                  accessor: "next_review_at",
                  width: 180,
                  Cell: props => {
                    if (props.value) {
                      const date = parseDate(props.value)
                      return <span>{format(date, "Do MMM YY")}</span>
                    }
                    return (
                      <span className="light-silver">&ndash;</span>
                    )
                  }
                },
                {
                  Header: "Latest Activity",
                  accessor: "updated_at",
                  width: 180,
                  Cell: props => {
                    if (props.value) {
                      const date = parseDate(props.value)
                      return <span>{format(date, "Do MMM YY")}</span>
                    }
                    return (
                      <span className="light-silver">&ndash;</span>
                    )
                  }
                },
                {
                  Header: "Date added",
                  accessor: "created_at",
                  width: 180,
                  Cell: props => {
                    if (props.value) {
                      const date = parseDate(props.value)
                      return <span>{format(date, "Do MMM YY")}</span>
                    }
                    return (
                      <span className="light-silver">&ndash;</span>
                    )
                  }
                }
              ]}

              // Controlled State Overrides (see Fully Controlled Component section)
              page={page}
              pageSize={pageSize}
              minRows={minRows}
              sorted={sorted}
              filtered={filtered}
              resized={resized}
              expanded={expanded}

              // Controlled State Callbacks
              onSortedChange={sorted => this.setState({ sorted }, this.fetchData)}
              onPageChange={page => this.setState({ page }, this.fetchData)}
              onPageSizeChange={(pageSize, page) =>
                this.setState({ page, pageSize }, this.fetchData)}
              onExpandedChange={expanded => this.setState({ expanded }, this.fetchData)}
              onResizedChange={resized => this.setState({ resized })}
              onFilteredChange={filtered => this.setState({ filtered }, this.fetchData)}

              getTdProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e, handleOriginal) => {
                    console.log("A Td Element was clicked!");
                    console.log("it produced this event:", e);
                    console.log("It was in this column:", column);
                    console.log("It was in this row:", rowInfo);
                    console.log("It was in this table instance:", instance);

                    // IMPORTANT! React-Table uses onClick internally to trigger
                    // events like expanding SubComponents and pivots.
                    // By default a custom 'onClick' handler will override this functionality.
                    // If you want to fire the original onClick handler, call the
                    // 'handleOriginal' function.
                    // if (handleOriginal) {
                    //   return handleOriginal();
                    // }

                    if (rowInfo) {
                      Turbolinks.visit(`/company/clients/${rowInfo.original.id}`)
                    }
                  }
                };
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}
