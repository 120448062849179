document.addEventListener("change", (e: any) => {
  // if (e.target === document.querySelector("[data-js-contextual]")) {
  if (e.target.hasAttribute("data-js-contextual")) {
    const group = e.target.getAttribute("data-js-contextual")
    const selected = e.target.value

    document.querySelectorAll<HTMLElement>(`[data-js-contextual-group="${group}"]`).forEach(panel => {
      const showOn = panel.getAttribute("data-js-contextual-show-on").split(",")
      if (showOn.includes(selected)) {
        panel.style.display = "block"
      } else {
        panel.style.display = "none"
      }
    })
  }
})

document.addEventListener("turbolinks:load", function(event) {

  document.querySelectorAll<HTMLSelectElement>(`[data-js-contextual]`).forEach(contextualElement => {
    const group = contextualElement.getAttribute("data-js-contextual")
    const selected = contextualElement.value

    document.querySelectorAll<HTMLElement>(`[data-js-contextual-group="${group}"]`).forEach(panel => {
      const showOn = panel.getAttribute("data-js-contextual-show-on").split(",")
      if (showOn.includes(selected)) {
        panel.style.display = "block"
      } else {
        panel.style.display = "none"
      }
    })    
  })
});