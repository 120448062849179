import React from "react"

function getProblemText(reason) {
  switch (reason) {
    case "not_mine":
      return "Our team have been notified this is not your will."
    case "will_with_new_solicitor":
    case "updated_new_solicitor":
      return "To use AssetNexus, your Will needs to be stored with us."
    default:
      return "We will check our records and get back to you. We apologise for any inconvience caused."
  }
}

const Confirm = ({ problem, reason, confirmUrl }) => {
  const csrf = document.querySelector(`meta[name="csrf-token"]`).content

  const problemText = getProblemText(reason)

  return (
    <>
      <div className="content-box flex flex-column items-center">
        <div className="inner">

          <span className="icon -thumbs-up mv5"></span>
          {problem ?
            <>
              <h1 className="page-title mb2">Thank you for your feedback.</h1>
              <p className="tc lh-copy">
                <strong>{problemText}</strong><br />
                If you would like to contact us please choose one of the following options:
              </p>
              <p className="tl self-start">
                Phone<br />
                <span className="f4 near-black">0207 856 0274</span>
              </p>
              <p className="tl self-start">
                Email<br />
                <a className="f4 near-black" href="mailto:info@assetnexus.io" target="_blank">info@assetnexus.io</a>
              </p>
            </>
          :
            <>
              <h1 className="page-title mb0">Your will has been confirmed</h1>
              <p className="tc  lh-copy">Your account set up is now complete.</p>
            </>
          }

          <form action={confirmUrl} method="POST">
            <input type="hidden" name="authenticity_token" value={csrf} />
            {problem &&
              <input type="hidden" name="problem_with_will" value={reason} />
            }
            {["not_mine", "updated_new_solicitor", "will_with_new_solicitor"].indexOf(reason) > -1 ?
              <button submit className="button -primary mv4">Cancel registration</button>
            :
              <button submit className="button -primary mv4">Contine to Asset Nexus</button>
            }
          </form>
        </div>
      </div>
    </>
  )
}

export default Confirm
