import React, { ReactNode } from "react"
import ReactTooltip from "react-tooltip"

type Props = {
  name: string
  align: string
  timestamp: string
  type: string
  data: any
  attach_symbol: string
}

const ChatLine = ({ name, align, timestamp, type, data, attach_symbol }: Props) => {
  let oAuthAccessToken = localStorage.getItem('oAuthAccessToken')
  let chat_align = ''
  let body
  if (align === "left") {
    chat_align = "chatline-align-left"
  } else if (align === "right") {
    chat_align = "chatline-align-right"
  }
  if (type === "1") { //chat
    body = <div className={"chatline-body " + chat_align}>{data}</div>
    let pos = data.search(attach_symbol)
    if (pos === 0) { //attachment link
      let str = data.substring(attach_symbol.length, data.length - 1)
      let fileName = ''
      let downloadLink = ''
      pos = str.search(':')
      if (pos >= 0) {
        fileName = str.substring(0, pos)
        downloadLink = str.substring(pos + 1, str.length - 1)
      }
      body =  <div className={"chatline-body " + chat_align}>
                <i className="material-icons">insert_drive_file</i>
                <span className="chatline-video">{fileName}</span>
                <a href={downloadLink}><i className="material-icons">cloud_download</i></a>
              </div>
    }
  } else if (type === "2") { //video
    let min = Math.ceil(parseInt(data.duration) / 60)
    let duration = '' + min + ' minute(s)'
    body =  <div className={"chatline-body " + chat_align}>
              <i className="material-icons">call</i>
              <span className="chatline-video">Video Call - {duration}</span>
              <a href={data.link + "?access_token=" + oAuthAccessToken}><i className="material-icons">cloud_download</i></a>
            </div>
  } else {
    body = <div className={"chatline-body " + chat_align}></div>
  }
  let unix_timestamp = parseInt(timestamp, 10)
  let short_time = new Intl.DateTimeFormat('en-US', {hour: 'numeric', hour12: true, minute: '2-digit'}).format(unix_timestamp)
  let long_time = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: 'numeric', hour12: true, minute: '2-digit', second: '2-digit'}).format(unix_timestamp)

  return (
    <div>
      <div className={"chatline-header " + chat_align} data-tip={long_time} data-for={name + timestamp}>{name} {short_time}</div>
      {body}  
      <ReactTooltip id={name + timestamp}/>
    </div>
  )
}

export default ChatLine
