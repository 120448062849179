import React from 'react'

export default class ZoomAuth extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { access_token, refresh_token, return_code, logged_email } = this.props
    let oAuthEmail = localStorage.getItem('oAuthEmail')
    let partner = localStorage.getItem('partner')
    let returnUrl = localStorage.getItem('returnUrl')
    if (return_code === "empty_token") {
      alert('Zoom login failed, please try again')
      this.handleLoginFail()
    } else if (return_code === "inactive_user") {
      alert('Please activate your zoom account')
      this.handleLoginFail()
    } else if (return_code === "success") {
      if (access_token === "" || refresh_token === "") {
        this.handleLoginFail()
        return
      }
      if (oAuthEmail === "" || oAuthEmail === null || oAuthEmail === undefined || partner === "" || partner === null || partner === undefined) {
        this.handleLoginFail()
        return
      }
      if (oAuthEmail !== logged_email) {
        alert('You are logged in as a different Zoom account.\nPlease log out from Zoom.us and try agian with the same Zoom account as your AssetNexus account.')
        this.handleLoginFail()
        return
      }
      console.log('returnUrl = ' + returnUrl)
      if (returnUrl === undefined || returnUrl === "") {
        returnUrl = '/'
      }
      localStorage.setItem('oAuthAccessToken', access_token)
      localStorage.setItem('oAuthRefreshToken', refresh_token)
      window.location.href = returnUrl
    } else {
      this.handleLoginFail()
    }
  }

  handleLoginFail() {
    localStorage.removeItem('oAuthAccessToken')
    localStorage.removeItem('oAuthRefreshToken')
    window.location.replace("/")
  }

  render() {
    return (
      <div>
        <h1 className="page-title">OAuth is redirecting you back to your site.</h1>
      </div>
    )
  }
}
