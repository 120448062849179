import React, { useState } from "react"
import Turbolinks from "turbolinks"

import Property from "./Property"
import Vehicle from "./Vehicle"
import Insurance from "./Insurance"
import Pension from "./Pension"
import { putRequest } from "../../helpers/api"

const steps = [
  "property",
  "vehicle",
  "insurance",
  "pension",
  "finished"
]

async function completeOnboarding() {
  await putRequest("/api/client/current_user", { asset_onboarding_dismissed: true })
  Turbolinks.visit("/")
}

const AssetOnboarding = ({ assetTypesAdded, mortgages, currentUser }) => {
  let userSteps = steps.filter(step => {
    switch (step) {
      case "property":
        return assetTypesAdded.indexOf("Assets::Property") < 0
      case "vehicle":
        return assetTypesAdded.indexOf("Assets::Vehicle") < 0
      case "insurance":
        return assetTypesAdded.indexOf("Assets::Insurance") < 0
      case "pension":
        return assetTypesAdded.indexOf("Assets::Pension") < 0
      default:
        return true
    }
  })

  if (userSteps.length === 1) {
    completeOnboarding()
    return null
  }

  const [step, setStep] = useState(userSteps[0])

  function nextStep(current) {
    const index = userSteps.indexOf(current)
    const next = userSteps[index + 1]
    setStep(next)
  }

  return (
    <div>
      {step === "property" &&
        <Property currentUser={currentUser} mortgages={mortgages || []} onNext={() => nextStep("property")} />
      }
      {step === "vehicle" &&
        <Vehicle onNext={() => nextStep("vehicle")} />
      }
      {step === "insurance" &&
        <Insurance onNext={() => nextStep("insurance")} />
      }
      {step === "pension" &&
        <Pension onNext={() => nextStep("pension")} />
      }
      {step === "finished" &&
        <>
          <span className="db light-silver fw4 f5 mb2">Add Assets</span>
          <h1 className="page-title">Thanks for adding your Assets</h1>
          <p>You're financial legacy is now being tracked in Asset Nexus.</p>
          <button onClick={() => completeOnboarding()} className="button -primary mt5">Back to dashboard</button>
        </>
      }
    </div>
  )
}

export default AssetOnboarding
