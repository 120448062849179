import React, { useState } from "react"

type Props = {
  continue_path: string,
  company_name: string,
  invited_by_name: string,
  end_user_will_path: string
}

const EndUserStepBlock = ({continue_path} : Props) => {
  const data = [
    {
      show: true,
      content: (
        <div className="bg-icon p1" >
          <h3 className="blue">View your Will</h3>
          <p>You can view your Will online.</p>
          <p>Circumstances such as marriage or divorce or the death of people named in your Will, will require it to be updated.</p>
          <p>Depending on your financial circumstances, inheritance tax might be an implication for you and generation financial planning might be something to look into.</p>
          <p>AssetNexus is here to keep in touch with your Solicitor so your wishes are supported.</p>
        </div>
      )
    },
    {
      show: false,
      content: (
        <div className="bg-icon p2" >
          <h3 className="blue">What exactly are you leaving behind?</h3>
          <p>A Will tells your executors how you want your estate to be given to your
beneficiaries.</p>
          <p>But what is your estate and where can it be accessed? AssetNexus allows you to record your estate so your beneficiaries</p>
          <p>Its all well and true to say you have a life insurance policy, but who is the provider, how much does the policy cover, when does it expiry?</p>
        </div>
      )
    },
    {
      show: false,
      content: (
        <div className="bg-icon p3" >
          <h3 className="blue">Net Present Value</h3>
          <p>But we don’t just allow you to record your portfolio.</p>
          <p>We tell you what your portfolio is worth, both before and after your death.</p>
          <p>Knowing your net worth will inform you as to what specifically you are leaving behind to your beneficiaries.</p>
          <p>You may have too much debt and leave next to nothing behind. You will not know for sure until we capture everything.</p>
        </div>
      )
    },
    {
      show: false,
      content: (
        <div className="bg-icon p4" >
          <h3 className="blue">Monitor Asset Values</h3>
          <p>We monitor and track the value of all of your assets and debts that make up your estate.</p>
          <p>Has your property increased, providing you with more equity?</p>
          <p>Has the mortgage decreased, reducing the debt on your estate?</p>
          <p>AssetNexus combines all keeps you updated as your assets and debts increase and decrease in value.</p>
        </div>
      )
    }
  ]

  const [steps, setSteps] = useState(data)

  const GoNext = (index) => {
    let newSteps = [...steps]
    newSteps[index].show = false
    console.log(newSteps[index+1])
    if (newSteps[index+1]) {
      newSteps[index+1].show = true
    }
    setSteps(newSteps)
  };

  return (
    <div className="step-block" >
      <a className="step-block__close" href={continue_path} ><i className="material-icons">close</i></a>

        {steps.map((step, i) => {
            let nextBtnTxt = 'Next'

            if ((i + 1) == steps.length) {
              nextBtnTxt = 'Welcome to AssetNexus'
            }

            let nextBtn = <a href={continue_path} className="step-block__next" >{nextBtnTxt} <i className="material-icons">keyboard_arrow_right</i></a>            

            if (steps[i+1]) {
              nextBtn = <a href="#" className="step-block__next" onClick={e => {
                e.preventDefault()
                GoNext(i)
              }} >{nextBtnTxt} <i className="material-icons">keyboard_arrow_right</i></a>
            }

            return (
              <div className={step.show ? '' : 'dn'} key={i}>
                {step.content}
                {nextBtn}
              </div>
            )
          })}
    </div>
  )
}

export default EndUserStepBlock
