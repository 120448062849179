import React, { useState } from "react"

type Props = {
  continue_path: string,
  company_name: string,
  invited_by_name: string,
  end_user_will_path: string,
  company_logo_src: string
}

const ExecutorStepBlock = ({continue_path, company_name, invited_by_name, end_user_will_path, company_logo_src} : Props) => {
  const data = [
    {
      show: true,
      content: (
        <div>
          <h3>You are an Executor</h3>
          <p>Who are we?</p>
          <p>Asset Nexus is a cloud-based platform used by solicitors that holds a digital a copy of {invited_by_name} Will online.</p>
          <p>{invited_by_name} can contact {company_name} to amend his Will at key points in life such as personal or financial circumstances changing.</p>
        </div>
      )
    },
    {
      show: false,
      content: (
        <div>
          <h3>Executor Responsibilities</h3>
          <p>What do you need to do?</p>
          <p>For now, not a whole lot.  Your responsibilities as executor only begin upon {invited_by_name} death.</p>
          <p>However, you should familiarise yourself with the roles and responsibilities of an executor, which we have outlined for you on the next page.</p>
        </div>
      )
    },
    {
      show: false,
      content: (
        <div>
          <h3>Financial Responsibility</h3>
          <p>Do you know the whereabouts of {invited_by_name} assets? You will need to identify all his assets and if inheritance tax needs paid. Sometimes assets can get lost because no documents exist about them. This will reduce the value of both the estate and legacy.</p>
          <p>You then need to value of all the assets, this means obtaining professional survey reports and engaging other professional services. If you get something wrong this could result in penalties with the relevant tax authorities. At the outset, this role looks very complex and can get stressful very quickly on top of everything else.</p>
        </div>
      )
    },
    {
      show: false,
      content: (
        <div>
          <h3>Legal Responsibility</h3>
          <p>The Executor has a huge legal responsibility involving; obtaining a grant of probate from the Court to then enable them to collect all assets and pay off any debts.</p>
          <p>Furthermore, you must give the beneficiaries what they are due and resolve any legal disputes should they occur. Legal disputes can add years of stress onto the probate process and delay beneficiaries receiving their gifts.</p>
        </div>
      )
    },
    {
      show: false,
      content: (
        <div>
          <h3>What happens next?</h3>
          <p>You will undoubtly have lots of questions, after reading some of the legal and financial elements involved with this role. This is perfectly normal, unfortunately, there is no training for executors today, you are required to start straight away at the point of death with no prior knowledge or training.</p>
          <p>We would encourage you to familiarise yourself with the Asset Nexus platform.</p>
          <p>Asset Nexus is here to help and prepare you for the role in advance of death, allowing you to fully consider what is required and what is involved in executing the role. Use the Asset Nexus platform to communicate with {company_name} and view our frequently asked questions to help prepare you for the road ahead.</p>
          <p>If you would like to make a Will, need legal advice, or would like to refer our platform to another solicitor, family member or friend, you can do so once you access the platform.</p>
          <div>
            <img height="100" src={company_logo_src} />
          </div>
        </div>
      )
    }
  ]

  const [steps, setSteps] = useState(data)

  const GoNext = (index) => {
    let newSteps = [...steps]
    newSteps[index].show = false
    console.log(newSteps[index+1])
    if (newSteps[index+1]) {
      newSteps[index+1].show = true
    }
    setSteps(newSteps)
  };

  return (
    <div className="step-block" >
      <a className="step-block__close" href={continue_path} ><i className="material-icons">close</i></a>

        {steps.map((step, i) => {
            let nextBtn = <a href={continue_path} className="step-block__next" >Welcome to Asset Nexus <i className="material-icons">keyboard_arrow_right</i></a>

            if (steps[i+1]) {
              nextBtn = <a href="#" className="step-block__next" onClick={e => {
                e.preventDefault()
                GoNext(i)
              }} >Next <i className="material-icons">keyboard_arrow_right</i></a>
            }

            return (
              <div className={step.show ? '' : 'dn'} key={i}>
                {step.content}
                {nextBtn}
              </div>
            )
          })}
    </div>
  )
}

export default ExecutorStepBlock
