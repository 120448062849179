import React, { useState } from "react"

import DisplayDocuments from "./DisplayDocuments"
import Problem from "./Problem"
import ProblemUpdate from "./ProblemUpdate"
import Confirm from "./Confirm"

const ConfirmWill = ({ client, documents, currentCompany, confirmUrl }) => {
  const [form, setValues] = useState({
    stage: "root"
  })
  return (
    <div className="narrow-container">
      {form.stage === "root" &&
        <DisplayDocuments
          documents={documents}
          solicitor={currentCompany}
          confirmUrl={confirmUrl}
          // onConfirmation={() => setValues({ ...form, stage: "confirm", problem: false })}
          onProblem={() => setValues({ ...form, stage: "problem" })}
        />
      }
      {form.stage === "problem" &&
        <Problem
          onContinue={reason => {
            let stage = "confirm"
            if (reason === "been_updated") {
              stage = "problem_update"
            }
            setValues({ ...form, stage, problem: true, reason, reasonExtra: null })
          }}
          onBack={() => setValues({ ...form, stage: "root" })}
        />
      }
      {form.stage === "problem_update" &&
        <ProblemUpdate
          solicitor={currentCompany}
          onContinue={reasonExtra => setValues({ ...form, stage: "confirm", problem: true, reasonExtra })}
          onBack={() => setValues({ ...form, stage: "problem" })}
        />
      }
      {form.stage === "confirm" &&
        <Confirm
          problem={form.problem}
          reason={form.reasonExtra || form.reason}
          confirmUrl={confirmUrl}
          onBack={() => {
            let stage = "root"
            if (form.problem) stage = "problem"
            if (form.reasonExtra) stage = "problem_update"
            setValues({ ...form, stage })
          }}
        />
      }
    </div>
  )
}

export default ConfirmWill
