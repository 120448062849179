import React, { useState } from "react"

type Props = {
  continue_path: string,
  company_name: string,
  invited_by_name: string,
  end_user_will_path: string,
  company_logo_src: string
}

const BeneficiaryStepBlock = ({continue_path, company_name, invited_by_name, end_user_will_path, company_logo_src} : Props) => {
  const data = [
    {
      show: true,
      content: (
        <div>
          <p>{invited_by_name} recently made a Will with {company_name}</p>
          <p>We can confirm that {invited_by_name} named you as a beneficiary of his Will.</p>
          <p>We further confirm that {invited_by_name} consented to us contacting you in this regard.</p>
        </div>
      )
    },
    {
      show: false,
      content: (
        <div>
          <h3>You are a Beneficiary</h3>
          <p>Who are we?</p>
          <p>Asset Nexus is a cloud-based platform used by us that hold a digital a copy of {invited_by_name}’s Will online.</p>
          <p>This allows {invited_by_name} to access his Will at any time at the click of a button.</p>
          <p>{invited_by_name} can contact us to amend his  Will at key points throughout his  lifetime should he wish to do so.</p>
        </div>
      )
    },
    {
      show: false,
      content: (
        <div>
          <h3>Your Key Role</h3>
          <p>Why are you contacting me?</p>
          <p>We are contacting you at {invited_by_name}’s request, to notify you that you are a named beneficiary in his Will.</p>
          <p>We suggest you familiarise yourself with the Asset Nexus platform as it may be of interest to you personally. If anything should happen to {invited_by_name} we will need to know. We hold his  Original Will which is required to unlock the gifts you are due.</p>
          <p>If you would like to make a Will or would like to refer our platform to another solicitor, family member or friend, you can do so once you access the platform.</p>
          <div>
            <img height="100" src={company_logo_src} />
          </div>
        </div>
      )
    }
  ]

  const [steps, setSteps] = useState(data)

  const GoNext = (index) => {
    let newSteps = [...steps]
    newSteps[index].show = false
    console.log(newSteps[index+1])
    if (newSteps[index+1]) {
      newSteps[index+1].show = true
    }
    setSteps(newSteps)
  };

  return (
    <div className="step-block" >
      <a className="step-block__close" href={continue_path} ><i className="material-icons">close</i></a>

        {steps.map((step, i) => {
            let nextBtn = <a href={continue_path} className="step-block__next" >Welcome to Asset Nexus <i className="material-icons">keyboard_arrow_right</i></a>

            if (steps[i+1]) {
              nextBtn = <a href="#" className="step-block__next" onClick={e => {
                e.preventDefault()
                GoNext(i)
              }} >Next <i className="material-icons">keyboard_arrow_right</i></a>
            }

            return (
              <div className={step.show ? '' : 'dn'} key={i}>
                {step.content}
                {nextBtn}
              </div>
            )
          })}
    </div>
  )
}

export default BeneficiaryStepBlock
