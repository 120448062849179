import React, { useState } from "react"
import HelpModal from "./HelpModal"

const HelpMenuItem = () => {
  const [showHelpModal, setHelpModal] = useState(false)

  return (
    <div>
      <a href="#" className="user-navigation__help"
        onClick={e => {
          e.preventDefault()
          setHelpModal(true)
        }}
      >?</a>
      <HelpModal open={showHelpModal} onClose={() => setHelpModal(false)} />
    </div>
  )
}

export default HelpMenuItem
