import _ from "lodash"
import React, { useState } from "react"
import { postRequest, putRequest } from "../../helpers/api"

const initialUser = {
  role: "user",
  email: "",
  first_name: "",
  last_name: "",
  password: ""
}

export default ({ assignableRoles, existing, onSave, onCancel }) => {
  const [form, setValues] = useState({ ...initialUser, ...existing })
  const [errors, setErrors] = useState({})
  const [isSaving, setIsSaving] = useState(false)

  return (
    <form onSubmit={e => {
      e.preventDefault()
      console.log("form submit", form)
    }}>
      <div className="form-field">
        <label htmlFor="user_role">Role</label>
        <select id="user_role" value={form.role} onChange={e =>  setValues({ ...form, role: e.target.value })}>
          <option value="user">Standard User</option>
          {assignableRoles.includes("owner") &&
           <option value="owner">Account Owner</option>
          }
          {assignableRoles.includes("admin") &&
            <option value="admin">Admin Account (be careful)</option>
          }
        </select>
        {_.find(errors, row => row.field === "role") && <span className="hint -error">{_.find(errors, row => row.field === "role").errors.join(", ")}</span>}
      </div>

      <div className="form-field">
        <label htmlFor="user_email">Email</label>
        <input type="email" id="user_email" value={form.email} onChange={e =>  setValues({ ...form, email: e.target.value })} />
        {_.find(errors, row => row.field === "email") && <span className="hint -error">{_.find(errors, row => row.field === "email").errors.join(", ")}</span>}
      </div>

      <div className="form-field">
        <label htmlFor="user_first_name">First name</label>
        <input type="text" id="user_first_name" value={form.first_name} onChange={e =>  setValues({ ...form, first_name: e.target.value })} />
        {_.find(errors, row => row.field === "first_name") && <span className="hint -error">{_.find(errors, row => row.field === "first_name").errors.join(", ")}</span>}
      </div>

      <div className="form-field">
        <label htmlFor="user_last_name">Last name</label>
        <input type="text" id="user_last_name" value={form.last_name} onChange={e =>  setValues({ ...form, last_name: e.target.value })} />
        {_.find(errors, row => row.field === "last_name") && <span className="hint -error">{_.find(errors, row => row.field === "last_name").errors.join(", ")}</span>}
      </div>

      <div className="form-field">
        <label htmlFor="user_password">Password</label>
        <input type="password" id="user_password" value={form.password} onChange={e =>  setValues({ ...form, password: e.target.value })} />
        {existing ?
          <span className="hint">Leave blank to ignore (Must be at least 8 characters)</span>
        :
          <span className="hint">Must be at least 8 characters</span>
        }
        {_.find(errors, row => row.field === "password") && <span className="hint -error">{_.find(errors, row => row.field === "password").errors.join(", ")}</span>}
      </div>

      <div className="flex">
        <button
          className="button -primary mr4"
          disabled={isSaving}
          onClick={async () => {
            setErrors({})

            setIsSaving(true)
            let resp
            if (existing) {
              resp = await putRequest(`/api/company/users/${existing.id}`, {
                ...form,
                password: form.password || null
              })
            } else {
              resp = await postRequest(`/api/company/users`, {
                ...form
              })
            }
            const json = await resp.json()
            setIsSaving(false)

            if (resp.status === 422) {
              setErrors(json.validation_errors)
              return
            }

            setValues({ ...initialUser })
            onSave(json)
          }}
        >
          { isSaving ? "Saving..." : existing ?  "Update user" : "Create user" }
        </button>
        <button onClick={e => {
          e.preventDefault()
          onCancel()
        }} className="button">Cancel</button>
      </div>
    </form>
  )
}
