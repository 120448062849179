import React, { useState } from "react"
import { parse as dateFnsParse, format as formatDate } from "date-fns"
import DatePicker from "react-date-picker"

export function isDate(value) {
  return value instanceof Date && !isNaN(value.valueOf());
}

function parseDate(str: string): Date | null {
  try {
    const parsed = dateFnsParse(str)
    if (!isDate(parsed)) return null
    return parsed
  } catch(err) {
    return null
  }
}

interface Props {
  name: string
  defaultValue: string
  onDayChange(value: Date): void
}

export default ({ name, defaultValue, onDayChange }: Props) => {
  const [value, setValue] = useState(defaultValue || "")

  return (
  <DatePicker
    name={name}
    value={parseDate(value)}
    onChange={date => {
      const value = formatDate(date as Date, "YYYY-MM-DD")
      setValue(value)
      onDayChange && onDayChange(date as Date)      
    }}
    format={"dd/MM/y"}
  />)
}
