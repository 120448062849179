import { find, each, reduce } from "lodash"
import React, { useState } from "react"
import Turbolinks from "turbolinks"

import { dictionary } from "../../../helpers/types"
import { putRequest } from "../../../helpers/api"
import { formatCurrencyShort } from "../../../helpers/string"
import Modal from "../../Modal"
import RadioInput from "../../inputs/RadioInput"
import PropertyTypeSelector from "./PropertyTypeSelector"
import AddressFinder, { buildAddress, AddressInfo } from "../../AddressFinder"
import MoneyInput from "../../inputs/MoneyInput"
import DateInput from "../../inputs/DateInput"
import { format as formatDate } from "date-fns"

interface Props {
  assetId: number
  open: boolean
  onClose(): void
  values: dictionary
  mortgages: dictionary[],
  valuations: dictionary[]
}

function getInitialValues(values: dictionary, valuations: dictionary): dictionary {
  const keys = [
    "asset_sub_type", "address_line_1", "address_line_2",
    "address_town", "address_postcode", "address_country",
    "mortgage", "mortgage_id", "main_residence", "renting_to_tenant",
    "initial_value", "surveyor_name", "survey_value", "date_of_valuation"
  ]

  const initialPriceValue = getAssetInitialPriceValue(valuations)

  return reduce(keys, (memo, key) => {
    if (key === 'initial_value') {
      memo[key] = initialPriceValue
    } else {
      memo[key] = values[key]
    }
    return memo
  }, {})
}

const getAssetInitialPriceValue = (valuations) => {
  let initialValue = null
  if (valuations.length) {
    valuations.forEach(el => {
      if (el.source === 'Client') {
        initialValue = el.value
      }
    })
  }

  return initialValue
}

const EditForm = ({ assetId, mortgages, open, values, onClose, valuations = [] }: Props) => {
  const [isSaving, setIsSaving] = useState(false)
  const [errors, setErrors] = useState([])
  const [form, setValues] = useState({ ...getInitialValues(values, valuations) })
  const [initialValue, setInitialValue] = useState(getAssetInitialPriceValue(valuations))

  return (
    <Modal title="Edit Property" open={open} onClose={onClose} showClose={true}>
      <form
        onSubmit={async e => {
          e.preventDefault()
          if (isSaving) return
          setIsSaving(true)
          let errors = []

          each(form, (value, field) => {
            if (field === "address_line_2") return
            if (field === 'mortgage_id') return
            if (initialValue == null && field === 'initial_value') return
            if (field === 'renting_to_tenant' && form.main_residence === true) return
            if (value == null || value.length < 1) {
              errors.push({ field, errors: ["is required"] })
            }
          })

          if (errors.length) {
            setErrors(errors)
            setIsSaving(false)
            return
          }

          const resp = await putRequest(`/api/assets/${assetId}`, form)
          const json = await resp.json()

          if (resp.status === 200) {
            Turbolinks.visit()
          } else {
            setErrors(json.validation_errors)
          }

          setIsSaving(false)
        }}
      >
        <PropertyTypeSelector value={form.asset_sub_type} onChange={asset_sub_type => setValues({ ...form, asset_sub_type })} />
        {find(errors, row => row.field === "asset_sub_type") && <span className="hint -error">{find(errors, row => row.field === "asset_sub_type").errors.join(", ")}</span>}

        <AddressFinder
          errors={errors}
          value={form as AddressInfo}
          onChange={address => {
            setValues({ ...form, ...address })
          }}
        />

        <div className="form-field -maxlength">
          <label>Is there a mortgage on this property?</label>
          <RadioInput
            value={form.mortgage}
            onChange={mortgage => setValues({ ...form, mortgage })}
            options={[
              { value: true, label: "Yes" },
              { value: false, label: "No" },
            ]}
          />
          {find(errors, row => row.field === "mortgage") && <span className="hint -error">{find(errors, row => row.field === "mortgage").errors.join(", ")}</span>}
        </div>

        {(mortgages.length > 0 && form.mortgage === true) &&
          <div className="form-field -maxlength">
            <label htmlFor="property_id">Which mortgage belongs to this property?</label>
            <select id="mortgage_id" value={form.mortgage_id || ""} onChange={e => setValues({ ...form, mortgage_id: e.target.value })}>
              <option value="">Please select</option>
        {mortgages.map(mortgage => <option key={mortgage.id} value={mortgage.id}>{mortgage.title} {formatCurrencyShort(mortgage.current_value)}</option>)}
            </select>
            {find(errors, row => row.field === "mortgage_id") && <span className="hint -error">{find(errors, row => row.field === "mortgage_id").errors.join(", ")}</span>}
          </div>
        }

        <div className="form-field -maxlength">
          <label>Is this property your main residence?</label>
          <RadioInput
            value={form.main_residence}
            onChange={main_residence => setValues({ ...form, main_residence, renting_to_tenant: false })}
            options={[
              { value: true, label: "Yes" },
              { value: false, label: "No" },
            ]}
          />
          {find(errors, row => row.field === "main_residence") && <span className="hint -error">{find(errors, row => row.field === "main_residence").errors.join(", ")}</span>}
        </div>

        {form.main_residence === false &&
          <div className="form-field -maxlength">
            <label>Are you renting this property to a tenant?</label>
            <RadioInput
              value={form.renting_to_tenant}
              onChange={renting_to_tenant => setValues({ ...form, renting_to_tenant })}
              options={[
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ]}
            />
            {find(errors, row => row.field === "renting_to_tenant") &&
              <span className="hint -error">
                {find(errors, row => row.field === "renting_to_tenant").errors.join(", ")}
              </span>
            }
          </div>
        }

        <div className="form-field -maxlength">
          <label htmlFor="surveyor_name">Surveyor Name</label>
          <input
            type="text"
            id="surveyor_name"
            value={form.surveyor_name}
            onChange={(event) => {
              setValues({ ...form, surveyor_name: event.target.value })
            }}
          />
          {find(errors, row => row.field === "surveyor_name") &&
            <span className="hint -error">
              {find(errors, row => row.field === "surveyor_name").errors.join(", ")}
            </span>
          }
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="survey_value">Survey Value</label>
          <MoneyInput id="survey_value" defaultValue={form.survey_value} onChange={survey_value => setValues({ ...form, survey_value })} />
          {find(errors, row => row.field === "survey_value") &&
            <span className="hint -error">
              {find(errors, row => row.field === "survey_value").errors.join(", ")}
            </span>
          }
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="date_of_valuation">Date of valuation</label>
          <DateInput value={form.date_of_valuation || ""} onDayChange={date => setValues({ ...form, date_of_valuation: formatDate(date, "YYYY-MM-DD") })} />
          {find(errors, row => row.field === "date_of_valuation") &&
            <span className="hint -error">
              {find(errors, row => row.field === "date_of_valuation").errors.join(", ")}
            </span>
          }
        </div>

        {Number.isInteger(initialValue) &&
          <div className="form-field -maxlength">
            <label htmlFor="initial_value">Purchase Amount</label>
            <MoneyInput id="initial_value" defaultValue={form.initial_value} onChange={initial_value => setValues({ ...form, initial_value })} />
            {find(errors, row => row.field === "initial_value") &&
              <span className="hint -error">
                {find(errors, row => row.field === "initial_value").errors.join(", ")}
              </span>
            }
          </div>
        }

        <div className="flex">
          <button type="submit" className="button -primary mr4" disabled={isSaving}>
            { isSaving ? "Saving..." : "Save changes" }
          </button>
          <button onClick={onClose} className="button">Cancel</button>
        </div>
      </form>
    </Modal>
  )
}

export default EditForm
