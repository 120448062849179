import { find, each, reduce } from "lodash"
import React, { useState } from "react"
import Turbolinks from "turbolinks"
import { format as formatDate } from "date-fns"

import { dictionary } from "../../../helpers/types"
import { putRequest } from "../../../helpers/api"
import Modal from "../../Modal"
import MoneyInput from "../../inputs/MoneyInput"
import DateInput from "../../inputs/DateInput"
import IconBigButton from "../../IconBigButton"

import lifeIcon from "../../NewAsset/icons/insurance-life.svg"
import healthIcon from "../../NewAsset/icons/insurance-health.svg"
import homeIcon from "../../NewAsset/icons/insurance-home.svg"
import incomeProtectionIcon from "../../NewAsset/icons/insurance-income-protection.svg"

interface Props {
  assetId: number
  open: boolean
  onClose(): void
  values: dictionary
}

function getInitialValues(values: dictionary): dictionary {
  const keys = ["asset_sub_type","provider_name","current_value","policy_reference","policy_claim_number","policy_start_date","policy_end_date"]

  return reduce(keys, (memo, key) => {
    memo[key] = values[key]
    return memo
  }, {})
}

const EditForm = ({ assetId, open, values, onClose }: Props) => {
  const [isSaving, setIsSaving] = useState(false)
  const [errors, setErrors] = useState([])
  const [form, setValues] = useState({ ...getInitialValues(values) })

  return (
    <Modal title="Edit Policy" open={open} onClose={onClose} showClose={true}>
      <form
        onSubmit={async e => {
          e.preventDefault()
          if (isSaving) return
          setIsSaving(true)
          let errors = []

          each(form, (value, field) => {
            if (["policy_reference", "policy_claim_number", "policy_start_date", "policy_end_date"].indexOf(field) > -1 ) return
            if (value == null || value.length < 1) {
              errors.push({ field, errors: ["is required"] })
            }
          })

          if (errors.length) {
            setErrors(errors)
            setIsSaving(false)
            return
          }

          const resp = await putRequest(`/api/assets/${assetId}`, form)
          const json = await resp.json()

          if (resp.status === 200) {
            Turbolinks.visit()
          } else {
            setErrors(json.validation_errors)
          }

          setIsSaving(false)
        }}
      >
        <div className="mv5 flex justify-stretch flex-wrap">
          <IconBigButton
            label="Life"
            icon={lifeIcon}
            onClick={() => setValues({ ...form, asset_sub_type: "life" })}
            active={form.asset_sub_type === "life"}
            extraClasses={`mr4 mb5 flex-auto`}
          />

          <IconBigButton
            label="Health"
            icon={healthIcon}
            onClick={() => setValues({ ...form, asset_sub_type: "health" })}
            active={form.asset_sub_type === "health"}
            extraClasses={`mr4 mb5 flex-auto`}
          />

          <IconBigButton
            label="Home"
            icon={homeIcon}
            onClick={() => setValues({ ...form, asset_sub_type: "home" })}
            active={form.asset_sub_type === "home"}
            extraClasses={`mr4 mb5 flex-auto`}
          />

          <IconBigButton
            label="Mortgage Income Protection"
            icon={incomeProtectionIcon}
            onClick={() => setValues({ ...form, asset_sub_type: "income_protection" })}
            active={form.asset_sub_type === "income_protection"}
            extraClasses={`mb5 flex-auto`}
          />
        </div>
        {find(errors, row => row.field === "asset_sub_type") && <span className="hint -error">{find(errors, row => row.field === "asset_sub_type").errors.join(", ")}</span>}

        <div className="form-field -maxlength">
          <label htmlFor="provider_name">Provider name</label>
          <input type="text" id="provider_name" value={form.provider_name} onChange={e => setValues({ ...form, provider_name: e.target.value })} />
          {find(errors, row => row.field === "provider_name") && <span className="hint -error">{find(errors, row => row.field === "provider_name").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="current_value">Policy amount</label>
          <MoneyInput id="current_value" defaultValue={form.current_value} onChange={current_value => setValues({ ...form, current_value })} />
          {find(errors, row => row.field === "current_value") && <span className="hint -error">{find(errors, row => row.field === "current_value").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="policy_reference">Policy reference</label>
          <input type="text" id="policy_reference" value={form.policy_reference} onChange={e => setValues({ ...form, policy_reference: e.target.value })} />
          {find(errors, row => row.field === "policy_reference") && <span className="hint -error">{find(errors, row => row.field === "policy_reference").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="policy_claim_number">Policy claim number</label>
          <input type="text" id="policy_claim_number" value={form.policy_claim_number} onChange={e => setValues({ ...form, policy_claim_number: e.target.value })} />
          {find(errors, row => row.field === "policy_claim_number") && <span className="hint -error">{find(errors, row => row.field === "policy_claim_number").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="policy_start_date">Policy start date</label>
          <DateInput value={form.policy_start_date || ""} onDayChange={date => setValues({ ...form, policy_start_date: formatDate(date, "YYYY-MM-DD") })} />
          {find(errors, row => row.field === "policy_start_date") && <span className="hint -error">{find(errors, row => row.field === "policy_start_date").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="policy_end_date">Policy end date</label>
          <DateInput value={form.policy_end_date || ""} onDayChange={date => setValues({ ...form, policy_end_date: formatDate(date, "YYYY-MM-DD") })} />
          {find(errors, row => row.field === "policy_end_date") && <span className="hint -error">{find(errors, row => row.field === "policy_end_date").errors.join(", ")}</span>}
        </div>

        <div className="flex">
          <button type="submit" className="button -primary mr4" disabled={isSaving}>
            { isSaving ? "Saving..." : "Save changes" }
          </button>
          <button onClick={onClose} className="button">Cancel</button>
        </div>
      </form>
    </Modal>
  )
}

export default EditForm
