import { get } from "lodash"
import React, { useState } from "react"
import humanize from "string-humanize"
import { parse as parseDate, format as formatDate } from "date-fns"
import { postRequest } from "../../helpers/api"
import FlashAlert from "../FlashAlertWithPortal"

const ContactList = ({ contacts, onEdit, onDelete, onUpdate = null }) => {
  const [flashMsg, setFlashMsg] = useState(null)

  return (
    <div className="simple-grid">
      {contacts.length < 1 &&
        <div className="mv6 light-silver f3">
          No parties
        </div>
      }

      {contacts.map(contact => {
        const invitationSentAt = get(contact, 'end_user.invitation_sent_at')

        return (
          <div key={contact.id} className="content-box -flex">
            <div className={`inner object-box -${contact.role}`}>
              <div className="subcategory">{humanize(contact.role)}</div>
              <div className="title">{contact.title}</div>

              <p className="info break-word">
                {contact.telephone}

                {contact.email &&
                  <>
                    <br />
                    {contact.email}
                  </>
                }

                {contact.full_address &&
                  <>
                    <br /><br />
                    {contact.full_address}
                  </>
                }
              </p>

              <div className="flex items-center justify-between w-100">
                <span onClick={() => onEdit(contact)} className="blue underline pointer">Edit details</span>

                {contact.role === "trusted_friend" &&
                  <span
                    className="pa1 pointer"
                    onClick={() => {
                      if (!confirm("Are you sure?")) return
                      onDelete(contact)
                    }}
                  >
                    <i className="material-icons light-silver">delete</i>
                  </span>
                }
              </div>

              {(contact.role === 'trusted_friend' || contact.role === 'executor' || contact.role === 'trustee' || contact.role === 'beneficiary') &&
                <span className="mt3 f6 silver">
                  {invitationSentAt ?
                    <>
                      {contact.invite_status === 'pending' ?
                        `Invited on ${formatDate(parseDate(invitationSentAt), 'Do MMM')} • `
                        :
                        contact.invite_status === 'accepted' ?
                          `Accepted ${formatDate(parseDate(contact.invite_updated_at), 'Do MMM')} • Last login ${formatDate(parseDate(contact.end_user.last_sign_in_at), 'Do MMM')}`
                          :
                          `Declined ${formatDate(parseDate(contact.invite_updated_at), "Do MMM")} • `
                      }

                      {contact.invite_status !== 'accepted' &&
                        <span
                          className="blue underline pointer"
                          onClick={async () => {
                            await postRequest(`/api/client/contacts/${contact.id}/invite`)
                            setFlashMsg(`Invite has been re-sent to ${contact.full_name}`)
                            onUpdate()
                          }}
                        >
                          Re-send invite
                        </span>
                      }
                    </>
                    :
                    <>
                      {contact.email &&
                        <span
                          className="blue underline pointer"
                          onClick={async () => {
                            await postRequest(`/api/client/contacts/${contact.id}/invite`)
                            setFlashMsg(`Invite has been sent to ${contact.full_name}`)
                            onUpdate()
                          }}
                        >
                          Send an invite
                        </span>
                      }
                    </>
                  }
                </span>
              }
            </div>
          </div>
        )
      })}

      {flashMsg &&
        <FlashAlert
          type="notice"
          message={flashMsg}
          onClose={() => setFlashMsg(null)}
        />
      }
    </div>
  )
}

export default ContactList
