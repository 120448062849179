import { find } from "lodash"
import React from "react"

import { dictionary } from "../../helpers/types"
import RadioInput from "../inputs/RadioInput"
import { postRequest } from "../../helpers/api"
import { formatCurrencyShort } from "../../helpers/string"
import AddressFinder, { AddressInfo, buildAddress } from "../AddressFinder"
import FlashAlert from "../FlashAlertWithPortal";
import MoneyInput from "../inputs/MoneyInput"

interface PropertyForm {
  asset_sub_type: string,
  owns_property: boolean,
  address_line_1: string,
  address_line_2: string,
  address_town: string,
  address_postcode: string,
  address_country: string,
  mortgage: boolean,
  main_residence: boolean,
  rented: boolean,
  renting_to_tenant: boolean,
  mortgage_id: string,
  current_value: number,
}

interface FormError {
  field: string,
  errors: string[]
}

interface Props {
  currentUser: dictionary
  mortgages: dictionary[]
  onNext(): void
}

interface State {
  isSaving: boolean,
  errors: FormError[],
  form: PropertyForm
}

export default class Property extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    const { currentUser } = this.props
    const currentUserAddress = buildAddress(currentUser as AddressInfo)
    this.state = {
      isSaving: false,
      errors: [],
      form: {
        ...currentUserAddress,
        asset_sub_type: "residential",
        owns_property: null,
        mortgage: null,
        main_residence: null,
        rented: null,
        renting_to_tenant: null,
        mortgage_id: "",
        current_value: null,
        // address_line_1: "",
        // address_line_2: "",
        // address_town: "",
        // address_postcode: "",
        // address_country: "",
      }
    }
  }

  setFormValues(values) {
    this.setState({ form: values })
  }

  onSubmit = async (event) => {
    event.preventDefault()
    const { onNext } = this.props
    const { form, isSaving } = this.state

    if (isSaving) return

    this.setState({ isSaving: true })


    const resp = await postRequest(`/api/assets`, { asset_category: "property", ...form })
    const json = await resp.json()

    if (resp.status === 201) {
      onNext()
    } else {
      console.log("failed resp", resp)
      this.setState({ errors: json.validation_errors })
    }

    this.setState({ isSaving: false })
  }

  renderAlert() {
    if (this.state.form.owns_property === false) {
      const message = "YOU CAN ONLY ADD ASSETS WHICH YOU OWN." +
        " ASSETS WHICH YOU ARE RENTING DO NOT CARRY ANY MONETARY VALUE TOWARDS YOUR LEGACY"

      return (
        <FlashAlert
          type="error"
          message={message}
          onClose={() => {}}
        />
      )
    }
  }

  renderPropertyAddress() {
    const { errors, form } = this.state
    const address = buildAddress(form as AddressInfo)

    return (
      <AddressFinder
        value={address}
        errors={errors}
        onChange={ address => { console.log('address', address); this.setFormValues({ ...form, ...address })} }
      />
    )
  }

  renderOwnershipBlock() {
    const { currentUser } = this.props
    const { form } = this.state

    return (
      <div className="form-field -maxlength">
        <RadioInput
          options={[
            { value: true, label: "Yes" },
            { value: false, label: "No" },
          ]}
          value={form.owns_property}
          onChange={owns_property => {
            const newValues = { ...form, owns_property }
            if (owns_property) newValues.rented = false
            this.setFormValues(newValues)
          }}
        />
      </div>
    )
  }

  renderResidenceBlock() {
    const { form, errors } = this.state

    if (form.owns_property) {
      return (
        <div className="form-field -maxlength">
          <label>Is this property your main residence?</label>
          <RadioInput
            value={form.main_residence}
            onChange={main_residence => this.setFormValues({...form, main_residence})}
            options={[
              {value: true, label: "Yes"},
              {value: false, label: "No"},
            ]}
          />
          {find(errors, row => row.field === "main_residence") &&
          <span
            className="hint -error">{find(errors, row => row.field === "main_residence").errors.join(", ")}</span>
          }
        </div>
      )
    }
  }

  renderCurrentValueBlock() {
    const { form, errors } = this.state

    if (form.owns_property) {
      return (
        <div className="form-field -maxlength">
          <label>Purchase Amount</label>
          <MoneyInput id="current_value" defaultValue={form.current_value} onChange={current_value => this.setFormValues({ ...form, current_value: current_value })} />
          {find(errors, row => row.field === "current_value") &&
          <span
            className="hint -error">{find(errors, row => row.field === "current_value").errors.join(", ")}</span>
          }
        </div>
      )
    }
  }

  renderMortgageBlock() {
    const { errors, form } = this.state
    const { mortgages } = this.props

    if (!form.owns_property) return null
    if (form.main_residence === null) return null
    if (form.main_residence === false && form.renting_to_tenant === null) return null

    return (
      <>
        <div className="form-field -maxlength">
          <label>Is there a mortgage on this property?</label>
          <RadioInput
            value={form.mortgage}
            onChange={mortgage => this.setFormValues({ ...form, mortgage })}
            options={[
              { value: true, label: "Yes" },
              { value: false, label: "No" },
            ]}
          />
          {find(errors, row => row.field === "mortgage") && <span className="hint -error">{find(errors, row => row.field === "mortgage").errors.join(", ")}</span>}
        </div>

        {(mortgages.length > 0 && form.mortgage === true) &&
        <div className="form-field -maxlength">
          <label htmlFor="property_id">Which mortgage belongs to this property?</label>
          <select id="mortgage_id" value={form.mortgage_id || ""} onChange={e => this.setFormValues({ ...form, mortgage_id: e.target.value })}>
            <option value="">Please select</option>
            {mortgages.map(mortgage => <option key={mortgage.id} value={mortgage.id}>{mortgage.title} {formatCurrencyShort(mortgage.current_value)}</option>)}
          </select>
          {find(errors, row => row.field === "mortgage_id") && <span className="hint -error">{find(errors, row => row.field === "mortgage_id").errors.join(", ")}</span>}
        </div>
        }
      </>
    )
  }

  renderRentingBlock() {
    const { form } = this.state

    if (form.owns_property && form.main_residence === false) {
      return (
        <div className="form-field -maxlength">
          <label>Are you renting this property to a tenant?</label>
          <RadioInput
            value={form.renting_to_tenant}
            onChange={renting_to_tenant => this.setFormValues({...form, renting_to_tenant})}
            options={[
              {value: true, label: "Yes"},
              {value: false, label: "No"},
            ]}
          />
        </div>
      )
    }
  }

  renderActionButton() {
    const { isSaving, form } = this.state
    const { onNext } = this.props

    if (form.owns_property === null) return

    return (
      <div className="flex items-center mt5">
        {form.owns_property &&
          <button disabled={isSaving} type="submit" className="button -primary mr3">
            {isSaving ? 'Saving...' : 'Add property'}
          </button>
        }
        {!form.owns_property &&
          <button onClick={onNext} className="mr3 button -primary">Continue</button>
        }
      </div>
    )
  }

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        {this.renderAlert()}
        <span className="db light-silver fw4 f5 mb2">Add Assets</span>
        {this.renderPropertyAddress()}
        <h1 className="page-title mb0">Do you own this property?</h1>
        {this.renderOwnershipBlock()}
        {this.renderResidenceBlock()}
        {this.renderCurrentValueBlock()}
        {this.renderRentingBlock()}
        {this.renderMortgageBlock()}

        {this.renderActionButton()}
      </form>
    )
  }
}
