import React, { useState } from "react"
import { CSSTransition } from "react-transition-group"
import ClickOutside from "@phawk/react-clickoutside-component"
import HelpModal from "./HelpModal"

type Props = {
  name: string
  avatar: string
}

const UserMenu = ({ name, avatar }: Props) => {
  const [showing, setShowing] = useState(false)
  const [showHelpModal, setHelpModal] = useState(false)

  return (
    <ClickOutside onClickOutside={() => setShowing(false)}>
      <div className={`user-menu ph4 ${showing && "-open"}`}>
        <div className="toggle" onClick={() => setShowing(!showing)}>
          {avatar ?
            <span className="avatar -xsmall" style={{ backgroundImage: `url('${avatar}')` }}></span>
          :
            <span className="avatar -xsmall flex items-center justify-center"><i className="material-icons">person</i></span>
          }
          <span className="name">Hi {name}</span>
          <span className="caret"></span>
        </div>
        <CSSTransition
          in={showing}
          classNames="fade"
          timeout={300}
          unmountOnExit
        >
          <div className="dropdown flex flex-column">
            <a href="/profile" className="mb3">Edit profile</a><br />
            <a
              href="#"
              className="mb3"
              onClick={e => {
                e.preventDefault()
                setShowing(false)
                setHelpModal(true)
              }}
            >
              Help &amp; Support
            </a><br />
            <a rel="nofollow" data-method="delete" href="/users/sign_out">Sign out</a>
          </div>
        </CSSTransition>
        <HelpModal open={showHelpModal} onClose={() => setHelpModal(false)} />
      </div>
    </ClickOutside>
  )
}

export default UserMenu
