import React from 'react'
import ReactTable from 'react-table'
import Turbolinks from 'turbolinks'
import { format, parse as parseDate } from 'date-fns'

import { makeDefaultTableState, buildParams } from '../../helpers/table'
import { getRequest } from '../../helpers/api'

class ClientsTable extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = makeDefaultTableState({ pageSize: 20 })
  }

  componentDidMount() {
    setTimeout(this.fetchData, 100)
  }

  fetchData = async () => {
    this.setState({ isLoading: true })

    const params = buildParams(this.state)
    const response = await getRequest('/api/admin/clients', params)
    const json = await response.json()
    const { data, meta } = json

    this.setState({ data, meta, isLoading: false })
  }

  render() {
    const {
      data,
      meta,
      isLoading,
      searchQuery,
      page,
      pageSize,
      minRows,
      sorted,
      filtered,
      resized,
      expanded
    } = this.state

    return (
      <>
        <div className="flex flex-column  flex-row-ns items-center-ns mb5">
          <h1 className="flex-auto page-title mb0">Clients</h1>

          <div className="flex self-stretch items-center justify-between bg-white search-field mv4 mv0-ns">
            <label htmlFor="referralsTableSearch" className="flex items-center">
              <i className="material-icons">search</i>
            </label>

            <input
              id="referralsTableSearch"
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(event) => {
                this.setState({ searchQuery: event.target.value }, this.fetchData)
              }}
            />
          </div>
        </div>

        <div className="content-box">
          <div className="inner">
            <ReactTable
              filterable={false}
              loading={isLoading}
              data={data}
              pages={meta.total_pages}
              manual
              className="data-table"
              columns={[
                {
                  Header: 'Ref. No.',
                  accessor: 'ref',
                  width: 100
                },
                {
                  Header: 'Name',
                  accessor: 'last_name',
                  Cell: (props) => <span>{props.original.full_name}</span>
                },
                {
                  Header: 'Date of Birth',
                  accessor: 'date_of_birth',
                  Cell: (props) => {
                    if (props.value) {
                      const date = parseDate(props.value)
                      return <span>{format(date, 'Do MMMM YYYY')}</span>
                    } else {
                      return  <span className="light-silver">&ndash;</span>
                    }
                  }
                },
                {
                  Header: 'Status',
                  accessor: 'living',
                  sortable: false,
                  width: 125,
                  Cell: (props) => {
                    if (props.value) {
                      return <span>Will</span>
                    } else {
                      return <span>Probate</span>
                    }
                  }
                },
                {
                  Header: 'Solicitor',
                  accessor: 'solicitor',
                  sortable: false,
                  Cell: (props) => <span>{props.value}</span>
                }
              ]}

              page={page}
              pageSize={pageSize}
              minRows={minRows}
              sorted={sorted}
              filtered={filtered}
              resized={resized}
              expanded={expanded}

              onSortedChange={(sorted) => this.setState({ sorted }, this.fetchData)}
              onPageChange={(page) => this.setState({ page }, this.fetchData)}
              onPageSizeChange={(pageSize, page) => this.setState({ page, pageSize }, this.fetchData)}
              onExpandedChange={(expanded) => this.setState({ expanded }, this.fetchData)}
              onResizedChange={(resized) => this.setState({ resized })}
              onFilteredChange={(filtered) => this.setState({ filtered }, this.fetchData)}
            />
          </div>
        </div>
      </>
    )
  }
}

export default ClientsTable
