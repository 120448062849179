import React, { useState } from "react"
import { postRequest } from "../../helpers/api"
import InviteRequestStatusModal from "./InviteRequestStatusModal"

export default ({ client_carrier }) => {
  const [isSuccess, setSuccess] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const postRequestInvite = async () => {
    const resp = await postRequest(`/api/company/clients/${client_carrier.client.id}/invite_request`, null)
    console.log(resp)
    if (resp.status === 204) {
      setSuccess(true)
      setShowSuccessModal(true)
    } else {
      alert(`Oops, something goes wrong. Status code ${resp.status}`)
    }
  }

  const onClick = (e) => {
    e.preventDefault()
    postRequestInvite()
  }

  return (
    <div>
      {!isSuccess &&
        <>
          <a href="#" onClick={onClick}>Invite to Asset Nexus</a>
        </>
      }
      {isSuccess &&
        <>
          <span>Invite request sent!</span>
        </>
      }
      <InviteRequestStatusModal client={client_carrier.client} open={showSuccessModal} onClose={() => setShowSuccessModal(false)}></InviteRequestStatusModal>
    </div>
  )
}
