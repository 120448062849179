import React from 'react'
import ReactTable from 'react-table'
import Turbolinks from 'turbolinks'
import { format, parse as parseDate } from 'date-fns'

import { makeDefaultTableState, buildParams } from '../../helpers/table'
import { getRequest, postRequest, patchRequest } from '../../helpers/api'

class ClientsTable extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = makeDefaultTableState({ pageSize: 20 })
  }

  componentDidMount() {
    setTimeout(this.fetchData, 100)
  }

  fetchData = async () => {
    this.setState({ isLoading: true })

    const params = buildParams(this.state)
    const response = await getRequest('/api/admin/clients/waiting_invite', params)
    const json = await response.json()
    const { data, meta } = json

    this.setState({ data, meta, isLoading: false })
  }

  inviteClient = async (client_id) => {
    this.setState({ isLoading: true })

    const response = await postRequest(`/api/admin/clients/${client_id}/invite`, null)
    
    if (response.status === 200) {
      // 
    } else {
      alert('Something goes wrong...')
    }

    this.fetchData()
  }

  updateHasAdminAddedDocuments = async (client_id, value) => {
    this.setState({ isLoading: true })

    const params = { admin_added_documents: value }
    const response = await patchRequest(`/api/admin/clients/${client_id}`, params)
    
    if (response.status === 200) {
      // 
    } else {
      alert('Something goes wrong...')
    }

    this.fetchData()
  }

  render() {
    const {
      data,
      meta,
      isLoading,
      searchQuery,
      page,
      pageSize,
      minRows,
      sorted,
      filtered,
      resized,
      expanded
    } = this.state

    return (
      <>
        <div className="flex flex-column  flex-row-ns items-center-ns mb5">
          <h1 className="flex-auto page-title mb0">Legal documents</h1>

          <div className="flex self-stretch items-center justify-between bg-white search-field mv4 mv0-ns">
            <label htmlFor="referralsTableSearch" className="flex items-center">
              <i className="material-icons">search</i>
            </label>

            <input
              id="referralsTableSearch"
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(event) => {
                this.setState({ searchQuery: event.target.value }, this.fetchData)
              }}
            />
          </div>
        </div>

        <div className="content-box">
          <div className="inner">
            <ReactTable
              filterable={false}
              loading={isLoading}
              data={data}
              pages={meta.total_pages}
              manual
              className="data-table"
              columns={[
                {
                  Header: 'Solicitor',
                  accessor: 'solicitor',
                  width: 150,
                  sortable: false,
                  Cell: (props) => <span>{props.value}</span>
                },
                {
                  Header: 'Solicitor Name',
                  accessor: 'invitation_request_sent_by_user',
                  sortable: false,
                  width: 150,
                  Cell: (props) => <span>{props.value}</span>
                },
                {
                  Header: 'Name of Consumer',
                  accessor: 'first_name',
                  // sortable: false,
                  width: 180,
                  Cell: (props) => <span className='bb bl-0 br-0 bt-0 b--dotted bw1' >{props.original.full_name}</span>
                },
                {
                  Header: 'Document Type',
                  // accessor: '',
                  width: 150,
                  sortable: false,
                  Cell: (props) => {
                    if (props.original.will_documents.length) {
                      return <span>{props.original.will_documents[0].document_type}</span>
                    } else {
                      return <span>-</span>
                    }
                  }
                },
                {
                  Header: 'Date of Upload (by solicitor)',
                  accessor: 'upload_date',
                  width: 250,
                  sortable: false,
                  Cell: (props) => {
                    if (props.original.invitation_request_sent_at) {
                      const date = parseDate(props.original.invitation_request_sent_at)
                      return <span>{format(date, 'Do MMMM YYYY')}</span>
                    } else {
                      return  <span className="light-silver">&ndash;</span>
                    }
                  }
                },
                {
                  Header: 'Date Admin added documents',
                  accessor: 'date_admin_added_documents',
                  width: 250,
                  sortable: false,
                  Cell: (props) => {
                    if (props.value) {
                      const date = parseDate(props.value)
                      return <span>{format(date, 'Do MMMM YYYY')}</span>
                    } else {
                      return  <span className="light-silver">&ndash;</span>
                    }
                  }
                },
                {
                  Header: 'Has admin added the document to AssetNexus?',
                  accessor: 'admin_added_documents',
                  sortable: false,
                  width: 350,
                  Cell: (props) => {
                    console.log(props)

                    if (props.original.invitation_accepted) {
                      return <select className="w-100" disabled >
                               <option>Yes</option> 
                             </select>
                    } else {
                    
                      return <select onChange={(e) => {
                        let choice = e.target.value === 'yes' ? true : false
                        this.updateHasAdminAddedDocuments(props.original.id, choice)
                      }} value={props.value ? 'yes' : 'no'} className="w-100" >
                                <option value="no">No</option>
                                <option value="yes">Yes</option>
                              </select>
                    }


                  }
                },
                {
                  Header: 'Invited to AssetNexus',
                  accessor: 'invited',
                  sortable: false,
                  width: 200,
                  Cell: (props) => {
                    if (props.original.invitation_accepted) {
                      return <button disabled >Invitation accepted</button>                      
                    }
                    else if (props.original.invitation_sent_at) {
                      return <button 
                                disabled={!props.original.admin_added_documents}
                                onClick={() => {
                                this.inviteClient(props.original.id)
                              }} >Re-send invite</button>
                    } else {
                      return (
                      <button
                        disabled={!props.original.admin_added_documents}
                        onClick={() => {
                          this.inviteClient(props.original.id)
                        }} >Invite</button>)
                    }
                  }
                },
                {
                  Header: 'Date Invited',
                  accessor: 'invitation_sent_at',
                  sortable: false,
                  width: 200,
                  Cell: (props) => {
                    if (props.value) {
                      const date = parseDate(props.value)
                      return <span>{format(date, 'Do MMMM YYYY')}</span>
                    } else {
                      return  <span className="light-silver">&ndash;</span>
                    }
                  }
                },
                {
                  Header: 'Invited accepted by consumer',
                  accessor: 'invitation_accepted',
                  sortable: false,
                  width: 270,
                  Cell: (props) => {
                    return props.value ? 'Yes' : 'No'
                  }
                },
              ]}

              page={page}
              pageSize={pageSize}
              minRows={minRows}
              sorted={sorted}
              filtered={filtered}
              resized={resized}
              expanded={expanded}

              onSortedChange={(sorted) => this.setState({ sorted }, this.fetchData)}
              onPageChange={(page) => this.setState({ page }, this.fetchData)}
              onPageSizeChange={(pageSize, page) => this.setState({ page, pageSize }, this.fetchData)}
              onExpandedChange={(expanded) => this.setState({ expanded }, this.fetchData)}
              onResizedChange={(resized) => this.setState({ resized })}
              onFilteredChange={(filtered) => this.setState({ filtered }, this.fetchData)}

              getTdProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e, handleOriginal) => {
                    console.log("A Td Element was clicked!");
                    console.log("it produced this event:", e);
                    console.log("It was in this column:", column);
                    console.log("It was in this row:", rowInfo);
                    console.log("It was in this table instance:", instance);

                    // IMPORTANT! React-Table uses onClick internally to trigger
                    // events like expanding SubComponents and pivots.
                    // By default a custom 'onClick' handler will override this functionality.
                    // If you want to fire the original onClick handler, call the
                    // 'handleOriginal' function.
                    // if (handleOriginal) {
                    //   return handleOriginal();
                    // }

                    if (column.id === 'first_name') {
                      Turbolinks.visit(`/company/clients/${rowInfo.original.id}`)
                    }
                  }
                };
              }}              
            />
          </div>
        </div>
      </>
    )
  }
}

export default ClientsTable
