import qs from "qs"

type RequestMethod = "get" | "post" | "put" | "PATCH" | "delete"

const getCSRFToken = () => {
  const tag = document.querySelector<HTMLMetaElement>('meta[name="csrf-token"]')
  return tag && tag.content
}

const makeRequest = async (requestType: RequestMethod, url: string, params: any) => {
  return await fetch(url, {
    method: requestType,
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-Token": getCSRFToken()
    },
    credentials: "same-origin",
    body: JSON.stringify(params)
  })
}

export const getRequest = async (url: string, params: any) => {
  const queryString = qs.stringify(params)
  return await fetch(`${url}?${queryString}`, {
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest"
    }
  })
}

export const postRequest = async (url: string, params: any) => (await makeRequest("post", url, params))
export const putRequest = async (url: string, params: any) => (await makeRequest("put", url, params))
export const patchRequest = async (url: string, params: any) => (await makeRequest("PATCH", url, params))
export const deleteRequest = async (url: string, params: any) => (await makeRequest("delete", url, params))

export default {
  postRequest,
  putRequest,
  patchRequest,
  deleteRequest
}

